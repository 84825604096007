.boxes-list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin: -$spacing*.5;
    padding: 0;
    li {
      margin: $spacing*.5;
    }
    a {
      display: block;
      background-color: #f1f1f1;
      padding: $spacing;
      text-decoration: none;
      color: $body-font-color;
      transition: background-color .15s ease-out;
      &:hover,
      &:focus {
        background-color: darken(#f1f1f1,5%);
      }
    }
    span {
      font-weight: bold;
    }
}
