.site-menu {
  @media #{$medium-up} {
    justify-content: flex-end;
  }
  // li {
  //     @media screen and (min-width: 50em) {
  //         margin-left: $spacing * 0.5;
  //         margin-right: $spacing * 0.5;
  //     }
  // }
}
