.s {
  margin: 0 0 $spacing;
  clear: both;
  @media screen and (min-width: 50em) {
    margin-bottom: 0;
    margin-left: $spacing;
  }
  fieldset {
    border: 0;
    padding: 0;
    margin: 0;
    div {
      display: flex;
    }
  }
  label,
  legend {
    @include visually-hidden;
  }
  input {
    flex: 1 1 auto;
    padding: $spacing * 0.5 0 $spacing * 0.5 $spacing * 0.5;
    border-radius: 4px 0 0 4px;
    appearance: none;
    @media screen and (min-width: 50em) {
      padding-top: $spacing * 0.25;
      padding-bottom: $spacing * 0.25;
    }
  }
  button {
    background-color: #fff;
    padding: 0 $spacing * 0.5;
    border-radius: 0 4px 4px 0;
    img {
      margin: 0;
    }
  }

  &--home {
    margin-bottom: $spacing * 2;
    @media screen and (min-width: 30em) {
      max-width: 20rem;
      margin-left: auto;
      margin-right: auto;
    }
    @media #{$medium-up} {
      max-width: none;
      width: 100%;
    }
    input {
      color: $body-font-color;
      @media screen and (min-width: 30em) {
        padding-top: $spacing * 0.75;
        padding-bottom: $spacing * 0.75;
        padding-left: $spacing;
      }
    }
    button {
      @media screen and (min-width: 30em) {
        padding: 0 $spacing;
        img {
          width: rem-calc(29);
          height: rem-calc(29);
        }
      }
    }
  }
}
