.fitxa-oferta {
  .apartat {
    text-transform: uppercase;
    font-weight: bold;
    &:after {
      content: "";
      display: block;
      margin-top: $spacing * 0.5;
      width: $spacing * 10;
      height: 2px;
      background-color: #313131;
    }
  }
  h1 {
    font-size: rem-calc(32);
    line-height: 1.3;
    letter-spacing: 0;
    font-weight: 300;
    @media #{$medium-up} {
      font-size: rem-calc(34);
    }
    @media #{$large-up} {
      font-size: rem-calc(36);
    }
  }
  .ofertes-list__meta {
    display: block;
    @media #{$medium-up} {
      text-align: right;
    }
    > span {
      display: block;
      &:first-child {
        padding-bottom: $spacing * 0.5;
        &:after {
          content: "";
          display: block;
          width: $spacing * 5;
          height: 1px;
          background-color: #313131;
          margin-top: $spacing * 0.5;
          @media #{$medium-up} {
            margin-left: auto;
          }
        }
      }
    }
    svg {
      margin: 0 0 $spacing * 0.5 0;
    }
  }
}
