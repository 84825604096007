.entrevista {
  text-align: center;
  @media #{$medium-up} {
    text-align: left;
    padding: 0;
  }
  h2,
  h3 {
    &:after {
      display: none;
    }
  }
  h2 {
    font-weight: 800;
    color: #000;
    margin: 0 0 $spacing 0;
    font-size: rem-calc(26);
    background-color: #a6a9ad;
    padding-top: $spacing;
    padding-bottom: $spacing;
    @media #{$medium-up} {
      position: relative;
      &::before,
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        background-color: inherit;
        width: 100%;
      }
      &::before {
        left: -100%;
      }
      &::after {
        right: -100%;
      }
    }
  }
  h3 {
    font-size: rem-calc(22);
    margin: 0 0 $spacing * 0.5;
  }
  .role {
    font-style: italic;
    font-size: 1rem;
  }
  img {
    @media #{$medium-up} {
      margin-bottom: 0;
    }
  }
  blockquote {
    font-weight: 300;
    font-size: rem-calc(22);
    margin-bottom: $spacing;
    letter-spacing: -0.5px;
    @media #{$medium-up} {
      font-size: rem-calc(26);
      margin-top: $spacing * 2;
    }
    @media #{$large-up} {
      font-size: rem-calc(32);
      line-height: 1.3;
    }
  }
}

@media #{$medium-up} {
  .home .section.entrevista > .row {
    @supports (object-fit: cover) {
      display: flex;
      img {
        height: 100%;
        object-fit: cover;
      }
    }
  }
}