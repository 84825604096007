.info-box {
  border-left: 1px solid #000;
  padding-left: $spacing;
  height: 100%;
  margin-bottom: $spacing;
  @media #{$large-up} {
    width: 75%;
    .medium-6 & {
      width: 100%;
    }
  }
  h2 {
    font-weight: 800;
    letter-spacing: -0.65px;
    font-size: rem-calc(20);
    margin-bottom: $spacing*.5;
    @media #{$medium-up} {
      font-size: rem-calc(22);
    }
    @media #{$large-up} {
      font-size: rem-calc(24);
    }
    @media #{$xlarge-up} {
      font-size: rem-calc(26);
    }
    &::after {
      content: '';
      display: inline-block;
      font-size: 1rem;
      margin-left: $spacing*.25;
    }
  }
  p {
    font-size: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &--contact {
    h2 {
      &::after {
      @include sprite('ic_room_24px2');
    }
      
    }
  }
  &--schedule {
    h2 {
      &::after {
        @include sprite('ico-time');
      }
    }
  }
}

// columnes posades amb l'editor
.columns2 {
  @media screen and (min-width: 30em) {
    display: flex;
  }
}