.actualitat {
  margin-bottom: $spacing * 2;
  &.activitat-destacat{
    margin-bottom: 0 !important;
    .time{
        font-weight: 700;
        padding-bottom:$spacing ;
    }
    h2{
        margin-bottom:$spacing * 2;
        font-size: rem-calc(18);
        position: relative;
        &::after{
            background-color: #000;
            position: absolute;
            height: 2px;
            top: 2rem;
            left: 0;
            width: 235px;
            content: '';
        }

    }
    h3{
        margin-bottom:$spacing;
        a{
            text-decoration: none;
            font-size: rem-calc(24);
            font-weight: 700;
        }
    }

    @media screen and (max-width: 1024px) and (min-width: 768px) {
        .medium-6, .medium-4{
            width: 50% !important;
        }

    }
    .no-float{
        float: left;
    }

  }

  &__item {
    padding-bottom: $spacing;
    margin-bottom: $spacing;
    border-bottom: 1px solid #aeaeae;


    .actualitat--activitats & {
      display: flex;
      flex-direction: column;


      time {
        order: -1;
        font-weight: 300;
        font-size: rem-calc(24);
        letter-spacing: -0.2px;
        margin-bottom: $spacing * 0.25;
        color: #000;
        @media #{$medium-up} {
          font-size: rem-calc(28);
        }
        @media #{$large-up} {
          font-size: rem-calc(32);
        }
      }
    }
    li {
      margin-bottom: 0;
      &:last-child .actualitat__item {
        padding-bottom: 0;
        border-bottom: 0;
      }
    }
    a {
      color: #000;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    h3 {
      font-size: rem-calc(18);
      line-height: 1.4;
      font-weight: bold;
      margin-top: 0;
      margin-bottom: $spacing * 0.5;
      @media #{$medium-up} {
        font-size: rem-calc(20);
      }
    }
    .time {
      display: block;
      margin-bottom: $spacing;
      font-size: rem-calc(14);
      line-height: 1;
      color: #666;
      margin-bottom: $spacing * 0.5;
      font-weight: 600;
      color: darken($primary-color, 7%);
    }
    p {
      font-size: rem-calc(14);
      color: #666;
      margin-bottom: $spacing * 0.5;
    }
  }
}
