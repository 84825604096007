.resultats-membres {
    @include undolist;
    margin-bottom: $spacing * 3;
    li {
        margin: 0;
        padding: $spacing 0;
        display: flex;
        flex-direction: column;
        position: relative;
        transition: background-color 0.15s ease-out;
        background-color: #fff;
        @media screen and (min-width: 40em) {
            flex-direction: row;
            align-items: center;
        }
        &:nth-child(odd) {
            background-color: #f1f1f1;
        }
        &::before,
        &::after {
            content: "";
            display: block;
            position: absolute;
            background-color: inherit;
            top: 0;
            width: 100%;
            height: 100%;
            transition: background-color 0.15s ease-out;
        }
        &::before {
            left: -100%;
        }
        &::after {
            right: -100%;
        }
        > * {
            margin-bottom: 0;
            + * {
                margin-top: $spacing * 0.5;
                @media screen and (min-width: 40em) {
                    margin-top: 0;
                }
            }
        }
        &:hover {
            background-color: darken(#f1f1f1, 5%);
            &::before,
            &::after {
                background-color: inherit;
            }
        }
    }
    img {
        order: -1;
        margin-top: 0;
        margin-right: auto;
        border-radius: 50%;
        .ie9 & {
            margin-top: 1rem;
        }
        @media screen and (min-width: 40em) {
            flex-shrink: 0;
            margin: auto 0;
            .ie9 & {
                margin-top: 1rem;
            }
        }
    }
    h3 {
        margin-top: $spacing;
        font-size: 1rem;
        @media screen and (min-width: 40em) {
            margin-top: 0;
            margin-left: $spacing;
            .ie9 & {
                margin-left: 0;
            }
        }
    }
    p {
        font-size: rem-calc(14);
        @media screen and (min-width: 40em) {
            padding-left: $spacing;
            .ie9 & {
                text-align: left;
                padding-left: 0;
                margin-top: 1rem;
            }
        }
        &:first-of-type {
            color: #666666;
            @media screen and (min-width: 40em) {
                margin-left: auto;
                text-align: right;
                .ie9 & {
                    text-align: left;
                }
            }
        }
        &:last-of-type {
            font-style: italic;
            font-weight: 400;
            @media screen and (min-width: 40em) {
                border-left: 1px solid #000;
                margin-left: $spacing;
                white-space: nowrap;
                .ie9 & {
                    border-left: none;
                    margin-left: 0;
                    white-space: normal;
                }
            }
            @media #{$large-up} {
                flex-shrink: 0;
                flex-basis: 14rem;
            }
        }
    }
    a {
        @include hover;
        color: $body-font-color;
        &:after {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
        }
    }
}

// El display: flex fa petar l'IE10. Com que no trobo el per què, he hagut de fer anar això per evitar-ho: https://stackoverflow.com/a/24321386
@media screen and (min-width:0\0) {  
    .resultats-membres li {
        display: block !important;
        @media screen and (min-width: 40em) {
            > * + * {
                margin-top: $spacing * 0.5;
            }
            h3 {
                margin-left: 0;
            }
            p {
                text-align: left !important;
                padding-left: 0;
                border-left: none !important;
                margin-left: 0 !important;
                white-space: normal;
            }
        }
    }
}