.membre-fitxa {
    h1 {
        font-weight: 800;
        font-size: rem-calc(30);
        margin-bottom: $spacing * 0.5;
    }
    .role {
        font-size: rem-calc(14);
        font-style: italic;
        color: #000;
        margin-bottom: $spacing * 2;
    }
    h2 {
        font-size: rem-calc(14);
        margin-bottom: $spacing * 0.25;
        &:after {
            display: none;
        }
    }
    .recerca {
        margin-bottom: $spacing;
        @media #{$medium-up} {
            display: flex;
            > div:last-child {
                border-left: 1px solid #979797;
            }
            > div:only-child {
                border-left: none;
            }
            p {
                margin-bottom: 0;
            }
        }
        p {
            color: #000;
        }
    }
    .links {
        border-bottom: 1px solid #d7d7d7;
        margin-bottom: $spacing;
        a {
            color: $primary-color;
            font-weight: 600;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}
