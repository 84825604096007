.grid-list {
    &--espais {
        > * {
            background-color: #6e7b85;
            &:hover {
                background-color: #5a646b;
            }
        }
    }
    &--serveis {
        > * {
            background-color: #6e7b85;
            &:hover {
                background-color: #5a646b;
            }
        }
    }

    // Recerca
    &--recerca {
        h2 {
            //     font-size: rem-calc(21);
            margin-bottom: $spacing * 0.5;
            @media #{$medium-up} {
                //         font-size: rem-calc(21);
                margin-top: $spacing * 0.5;
            }
            // }
            // p {
            //     font-size: 0.9375rem;
        }
        span {
            @media #{$large-up} {
                margin-top: $spacing * 0.5;
            }
        }
        > * {
            @media screen and (min-width: 35em) {
                padding-top: 4rem;
            }
            @media #{$medium-up} {
                width: calc(24% - 1.5rem);
            }
            img {
                display: block !important;
                position: static;
                margin-bottom: $spacing * 0.5;
                @media screen and (min-width: 35em) {
                    position: absolute;
                    left: $spacing;
                    top: $spacing;
                }
            }
            &:first-child {
                padding-top: 1rem;
                @media #{$large-up} {
                    width: calc(33% - 1.5rem);
                }
                img {
                    position: static;
                    height: auto;
                    margin-bottom: 0;
                }
                span {
                    color: $primary-color;
                    border-color: $primary-color;
                    text-transform: uppercase;
                    font-weight: bold;
                    font-size: 0.75rem;
                    padding: 0.5rem 2rem;
                    &::before {
                        display: none;
                    }
                }
            }
            &:last-child {
                margin-left: 0;
                margin-top: 0;
                margin-right: 0;
            }
            @media screen and (min-width: 35em) {
                &:after {
                    content: "";
                    display: block;
                    background-image: linear-gradient(to bottom, rgba(#186e95, 0.12), transparent);
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    opacity: 0;
                    transition: opacity 0.15s ease-in-out;
                }
                &:hover:after {
                    opacity: 1;
                }
            }
        }
    }
}
