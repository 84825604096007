.centres-list {
    display: flex;
    flex-wrap: wrap;
    li {
        padding: 0 0 $spacing;
        margin: 0 0 $spacing;
        border-bottom: 1px solid #979797;
        flex-basis: 100%;
        @media screen and (min-width: 40em) {
            flex-basis: 50%;
            padding-left: $spacing;
            padding-right: $spacing;
        }
        @media #{$medium-up} {
            flex-basis: 25%;
            border-bottom: 0;
        }
        &::before {
            display: none;
        }
        &:last-child {
            margin-bottom: $spacing;
        }
        > * {
            margin: 0;
            + * {
                margin-top: $spacing * 0.5;
            }
        }
    }
    h2 {
        font-size: rem-calc(17);
    }
    p {
        font-size: rem-calc(15);
        &.adr {
            font-style: italic;
            padding-left: $spacing;
            position: relative;
            &::before {
                @include sprite("ic_room_24px2");
                content: "";
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                font-size: 1rem;
            }
        }
        &.tel {
            font-size: rem-calc(17);
            font-style: italic;
            @media #{$medium-up} {
                font-size: rem-calc(19);
            }
            @media #{$large-up} {
                font-size: rem-calc(21);
            }
            @media #{$xlarge-up} {
                font-size: rem-calc(24);
            }
            a {
                @include hover;
                color: $body-font-color;
                &::before {
                    @include sprite("ic_call_24px");
                    display: block;
                    content: '';
                    margin: 0 auto $spacing*.25;
                    font-size: 1rem;
                }
                &:after {
                    display: none;
                }
            }
        }
    }
}

.centre-destacat {
    // width: 100% !important;
    text-align: center;
    flex-basis: 100% !important;
    padding-left: 0;
    padding-right: 0;
    @media screen and (min-width: 40em) {
        border-bottom: 1px solid #979797 !important;
    }
    &::before {
        @include sprite("ic_room_24px2");
        content: '';
        display: block !important;
        font-size: 1rem;
        position: static !important;
        margin: 0 auto $spacing*.5;
        background-color: transparent !important;
    }
    p:first-of-type {
        @media screen and (min-width: 40em) {
            width: 60%;
            margin-left: auto;
            margin-right: auto;
        }
        @media #{$medium-up} {
            width: 50%;
        }
        @media #{$large-up} {
            width: 30%;
        }
    }
    p.adr {
        padding-left: 0;
        &::before {
            display: none;
        }
    }
}