.top-img-container {
    &--full {
        @media #{$large-up} {
            height: rem-calc(700);
        }
        .text-box {
            p {
                @media #{$medium-up} {
                    width: 40%;
                }
                @media #{$large-up} {
                    width: 30%;
                }
            }
        }
    }
}

