ol.galeria-m {
  .actualitat__item {
    position: relative;
    &:hover {
      .actualitat__img {
        opacity: .8;
        &::before {
          font-size: 1.075rem;
        }
      }      
    }
    .section--bg & {
      @media #{$medium-up} {
        padding-bottom: 0;
        border-bottom: 0;
        margin-bottom: 0;
      }
    }
  }
  time {
    color: inherit;
    &:after {
      display: none;
    }
  }
  h2, h3 {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: $spacing*.5;
    a {
      @include hover;
      color: inherit !important;
    }
  }
  a::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
  }
  .actualitat__img {
    order: -1;
    position: relative;
    margin-bottom: $spacing;
    transition: opacity .15s ease-out;
    img {
      height: 100%;
      margin: 0;
    }
    &::before {
      content: '';
      @include sprite('play-video-int');
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 1rem;
      transition: font-size .15s ease-out;
    }
  }
  .actualitat__descr {
    p {
      color: inherit;
    }
  }
}

// fitxa
.galeria-m.fitxa {
  time {
    font-size: rem-calc(14);
    font-weight: normal;
    margin-bottom: $spacing*.5;
    &::after {
      display: none;
    }
  }
  h1 {
    font-size: rem-calc(18);
    font-weight: bold;
    margin-bottom: $spacing*.5;
    + p {
      font-size: rem-calc(14);
    }
  }
  h2 {
    margin-top: 0;
  }
}
