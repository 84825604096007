.home-top {
  background-image: url("img/bg-top-home-recerca.jpg");
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media #{$retina} {
    background-image: url("img/bg-top-home-recerca@2x.jpg");
  }
  @media #{$large-up} {
    height: rem-calc(580);
  }
}

.boxed {
  @media #{$medium-up} {
    min-height: 5rem;
    padding: $spacing * 0.75;
  }
  img {
    margin-bottom: 0;
  }
}
.home-boxes {
  &__container {
    // @media #{$medium-up} {
    //   padding: 0 $spacing * 1.5;
    // }
    .h-col-r & {
      background: #ccc no-repeat url("img/imatge-sjd-institut.jpg");
      background-size: cover;
      text-align: center;
      padding: $spacing * 1.5 $spacing 0;
      @media #{$medium-up} {
        padding-left: $spacing * 3;
        padding-right: $spacing * 3;
      }
      @media #{$large-up} {
        padding-left: $spacing * 4;
        padding-right: $spacing * 4;
      }
      p {
        color: #fff;
        font-size: rem-calc(20);
        background: #000;
        background: rgba(0, 0, 0, 0.6);
        font-style: italic;
        padding: $spacing;
        margin-bottom: $spacing * 1.5;
        @media #{$medium-up} {
          font-size: rem-calc(22);
        }
        // @media #{$large-up} {
        //   font-size: rem-calc(26);
        // }
      }
      .btn {
        margin-bottom: 0;
        &--solid {
          background-color: #000;
          &:hover,
          &:focus {
            background-color: #fff;
          }
        }
      }
    }
    h3 {
      font-size: rem-calc(22);
      font-weight: bold;
      margin-top: $spacing;
      padding-bottom: $spacing * 0.5;
      border-bottom: 1px solid #000;
    }
    ul a {
      color: #666;
      @include hover;
    }
  }
}
.h-apartats-recerca {
  li:first-child {
    img {
      left: .70rem;
    }
  }
  li:last-child {
    img {
      left: 1.25rem;
    }
  }
}
.assaigs-container {
  background-image: url("img/bg-assaigs-home.jpg");
  @media #{$retina} {
    background-image: url("img/bg-assaigs-home@2x.jpg");
  }
  .wrapper {
    @media #{$medium-up} {
      width: 50%;
    }
  }
  .btn {
    width: 100%;
  }
}
.actualitat__meta {
  margin-top: $spacing * 0.5;
  p {
    margin-top: $spacing * 0.5;
  }
}
