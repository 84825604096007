.error-404 {
  h1 {
    text-transform: lowercase;
    color: #fff;
    background-color: #000;
    border-radius: 50%;
    height: $spacing * 9;
    width: $spacing * 9;
    font-weight: 800;
    font-size: rem-calc(42);
    letter-spacing: -0.64px;
    margin: 0 auto $spacing;
    display: flex;
    flex-direction: column;
    justify-content: center;
    span {
      display: block;
      font-size: rem-calc(63);
      letter-spacing: -0.96px;
      line-height: 1;
    }
    + p {
      strong {
        font-weight: 800;
        display: block;
      }
    }
  }
}
