table {
    thead {
        th {
            color: #666;
        }
    }
    tbody {
        tr > * {
            &:nth-child(odd) {
                background-color: rgb(210,215,218);
            }
            &:nth-child(even) {
                background-color: rgb(231,232,234);
            }
        }
    }
}