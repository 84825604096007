ul.publ {
  @include undolist;
  @media screen and (min-width: 40em) {
    column-count: 2;
    column-gap: $spacing;
  }
  li {
    margin: 0;
    padding: 0;
    break-inside: avoid;
    border-bottom: 1px solid #999;
    padding-bottom: $spacing*1.5;
    margin-bottom: $spacing*1.5;
    > * {
      margin: 0;
      + * {
        margin-top: $spacing*.5;
      }
    }
  }
  h3 {
    font-size: 1rem;
    line-height: 1.2;
  }
  dl,p {
    font-size: rem-calc(14);
  }
  dt {
    font-weight: 600;
  }
}