.icon {
    img {
        vertical-align: middle;
        margin: 0 $spacing * 0.25 0 0;
    }
    &:before {
        content: "";
        vertical-align: middle;
        margin-right: $spacing * 0.25;
        font-size: 1rem;
    }
    &--orcid {
        &:before {
            @include sprite("icon-orcid");
        }
    }
    &--researcheid {
        &:before {
            content: '';
            display: inline-block;
            background-image: url('img/ico-publons.png');
            width: 1.6875em;
            height: 1.75em;
        }
    }
    &--google-scholar {
        &:before {
            @include sprite("icon-google-scholar");
        }
    }
    &--blog {
        &:before {
            @include sprite("icon-blog");
        }
    }
    &--twitter {
        &:before {
            @include sprite("icon-twitter");
        }
    }
    &--linkedin {
        &:before {
            @include sprite("iconfinder_square-linkedin_317725");
        }
    }
    // &--upc {
    //   &:before {
    //     @include sprite("icon-upc");
    //   }
    // }
    &--loc {
        &:before {
            @include sprite("ic_room_24px");
        }
    }
    &--loc2 {
        &:before {
            @include sprite("ic_room_24px2");
        }
    }
    &--time {
        &:before {
            @include sprite("ico-time");
        }
    }
    &--adjunt {
        &:before {
            @include sprite("ico-adjunt");
        }
    }
    &--mail {
        &:before {
            @include sprite("ico-email");
        }
    }

    // home
    &--neuro {
        &:before {
            @include sprite("ico-neurociencies");
        }
    }
    &--cancer {
        &:before {
            @include sprite("ico-cancer-pediatric");
        }
    }
    &--resposta {
        &:before {
            @include sprite("ico-immunologia");
        }
    }
    &--tecnologia {
        &:before {
            @include sprite("ico-tecnologies");
        }
    }
    &--grups {
        &:before {
            @include sprite("ico-grups-associats");
        }
    }
}
