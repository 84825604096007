.news-list {
  list-style: none;
  padding-left: 0;
  @media screen and (min-width: 30em) {
    margin-left: $column-gutter * -0.5;
    margin-right: $column-gutter * -0.5;
    display: flex;
    flex-wrap: wrap;
  }
  li {
    margin-bottom: $spacing * 1.5;
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 30em) {
      // no flexbox fallback
      display: inline-block;
      vertical-align: top;

      display: flex;
      margin-left: $column-gutter * 0.5;
      margin-right: $column-gutter * 0.5;
      width: 45%;
      width: calc(50% - 3rem);
    }
    @media #{$medium-up} {
      width: 28%;
      width: calc(33.333% - 3rem);
    }
  }
  h2 {
    font-size: rem-calc(22);
    font-weight: bold;
    margin-bottom: $spacing * 0.5;
    @media #{$medium-up} {
      font-size: rem-calc(24);
    }
    @media #{$large-up} {
      font-size: rem-calc(26);
    }
    a {
      @include hover;
      color: inherit;
    }
  }
  .time {
    display: block;
    margin-bottom: $spacing;
    font-size: rem-calc(16);
    font-weight: bold;
    order: -1;
    @media #{$medium-up} {
      font-size: rem-calc(18);
    }
    &:after {
      content: "";
      display: block;
      width: $spacing * 8;
      height: 2px;
      background-color: #313131;
      margin-top: $spacing * 0.25;
    }
  }
  img {
    margin-right: auto;
    order: -1;
  }
  &__text {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
  }

  // notícies destacades
  &--dest {
    // @media screen and (min-width: 30em) {
    //   flex-wrap: nowrap;
    // }
    li {
      @media screen and (min-width: 30em) {
        width: 100%;
      }
      @media screen and (min-width: 40em) {
        flex-direction: row;
      }
    }
    h3 {
      font-size: rem-calc(30);
      @media #{$medium-up} {
        font-size: rem-calc(32);
      }
      @media #{$large-up} {
        font-size: rem-calc(34);
      }
    }
    img {
      @media screen and (min-width: 40em) {
        margin-bottom: auto;
        margin-right: $column-gutter * 0.5;
        flex: 0 0 auto;
      }
      @media #{$medium-up} {
        margin-right: $column-gutter;
      }
      @media #{$large-up} {
        margin-right: $column-gutter * 1.5;
      }
      @media #{$xlarge-up} {
        margin-right: $column-gutter * 2;
      }
    }
    // p {
    //   font-weight: 600;
    // }
  }
}
