.nav {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    background-color: #eee;
    @media screen and (min-width: 50em) {
        position: static;
        background-color: transparent;
    }
}
.nav-container--sticky {
    @media #{$medium-up} {
        position: fixed;
        z-index: 1;
        width: 100%;
        background-color: #fff;
        top: 0;
        box-shadow: 0 2px 6px 0px rgba(0, 0, 0, 0.25);
    }
}
.menu-button {
    margin: $spacing * 0.75 auto;
    display: block;
    &:focus {
        outline: none;
    }
    @media screen and (min-width: 50em) {
        display: none;
    }
}
.menu-wrapper {
    background-color: #eee;
    padding: $spacing * 0.5 $spacing $spacing $spacing;
    @media screen and (min-width: 50em) {
        background-color: transparent;
        padding: 0;
    }
}
.site-menu {
    list-style: none;
    text-transform: uppercase;
    margin-bottom: 0;
    text-align: center;
    font-weight: bold;
    letter-spacing: 0.78px;
    font-size: rem-calc(14);
    margin-bottom: $spacing * 2;
    @media screen and (min-width: 50em) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: -$spacing * 0.25 0;
        [lang="es"] & {
            letter-spacing: 0;
        }
    }
    @media #{$xlarge-up} {
        justify-content: space-between;
    }
    > * + * {
        margin-top: $spacing * 0.5;
        @media screen and (min-width: 50em) {
            margin-top: 0;
        }
    }
    li {
        @media screen and (min-width: 50em) {
            margin: $spacing * 0.25 $spacing * 0.125;
            /*flexbox fallback*/
            display: inline-block;
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
    a {
        color: #000;
        text-decoration: none;
        display: block;
        padding: $spacing * 0.25 $spacing * 0.5;
        border: 1px solid transparent;
        border-radius: 3px;
        transition: border-color 0.1s ease-in-out;
        &:hover,
        &:focus {
            border-color: #000;
            box-shadow: none;
        }
        &[aria-current="page"] {
            border-color: #000;
            .col & {
                border-color: $secondary-color;
            }
        }
    }
    .col a[aria-current="page"] {
        border-color: $secondary-color;
    }
}

.social-mobile {
    text-align: center;
    margin-bottom: 0;
    @media screen and (min-width: 50em) {
        display: none;
    }
    li {
        margin-bottom: $spacing;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
