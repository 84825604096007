.home-top {
    color: #fff;
    background-color: #666;
    background-position: 50% 0;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-image: url("img/bg-top-home.jpg");
    margin-bottom: $spacing;
    @media #{$retina} {
        background-image: url("img/bg-top-home@2x.jpg");
    }
    @media #{$large-up} {
        height: rem-calc(478);
        margin-bottom: $spacing * 3;
    }
    &--overlay {
        &:before {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            background-color: #666;
            background-color: rgba(0, 0, 0, 0.25);
        }
    }
    h2 {
        color: #fff;
        margin: $spacing * 2 0;
        font-size: rem-calc(30);
        text-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
        @media #{$medium-up} {
            margin: $spacing * 3 0;
            font-size: rem-calc(42);
        }
        @media #{$large-up} {
            margin: $spacing * 4 0;
            font-size: rem-calc(46);
        }
        &:after {
            display: none;
        }
    }
}

.boxed {
    padding: $spacing;
    color: #fff;
    margin-bottom: 0;
    font-weight: 800;
    font-size: rem-calc(22);
    @media #{$medium-up} {
        font-size: rem-calc(24);
    }
    @media #{$large-up} {
        font-size: rem-calc(26);
        padding: $spacing * 1.5;
    }
    &:after {
        display: none;
    }
    &--a {
        background-color: $primary-color;
    }
    &--b {
        background-color: #000;
    }
    a {
        color: #fff;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}
// .home-boxes {
//   @media #{$large-up} {
//     margin-top: -$spacing * 2;
//     position: relative;
//     z-index: 1;
//   }
// }
.h-apartats-recerca {
    font-weight: 600;
    font-size: rem-calc(17);
    @media #{$medium-up} {
        font-size: rem-calc(18);
    }
    li {
        margin-bottom: 0;
        &:first-child {
            img {
                left: 1rem;
            }
        }
        &:nth-child(3) {
            img {
                left: 1rem;
            }
        }
        &:nth-child(4) {
            img {
                left: 1.25rem;
            }
        }
        &:last-child {
            img {
                left: 1.125rem;
            }
        }
    }
    a {
        display: block;
        padding: $spacing * 0.5 $spacing * 0.25;
        text-decoration: none;
        color: #666;
        border: 1px solid transparent;
        border-bottom-color: $primary-color;
        margin-top: -1px;
        padding-left: rem-calc(80);
        position: relative;
        min-height: $spacing * 3.5;
        display: flex;
        flex-direction: column;
        justify-content: center;
        &:before,
        img {
            position: absolute;
            left: $spacing * 0.5;
            top: 50%;
            margin: 0;
            transform: translateY(-50%);
        }
        &:hover,
        &:focus {
            color: #000;
            text-decoration: underline;
        }
    }
}
.assaigs-container {
    padding: $spacing;
    background-color: #666;
    background-position: 50% 0;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-image: url("img/bg-assaigs-home.jpg");
    margin-bottom: $spacing;
    @media #{$retina} {
        background-image: url("img/bg-assaigs-home@2x.jpg");
    }
    .wrapper {
        // @media #{$medium-up} {
        //   width: 50%;
        // }
    }
    .wrapper-inner {
        padding: $spacing;
        margin-bottom: $spacing;
        background-color: #fff;
        background-color: rgba(255, 255, 255, 0.8);
    }
    h3 {
        margin: 0 0 $spacing;
        font-size: rem-calc(20);
        font-weight: bold;
        border: solid #000;
        border-width: 1px 0;
        padding: $spacing * 0.5 0;
        @media #{$medium-up} {
            font-size: rem-calc(22);
        }
    }
    p {
        margin-bottom: 0;
    }
    .btn {
        // width: 100%;
        margin: 0;
        margin-left: 50%;
        transform: translateX(-50%);
    }
}
.h-actualitat {
    h2 {
        font-weight: 800;
        text-align: center;
        // @media screen and (min-width: 40em) {
        //   text-align: left;
        // }
        @media #{$medium-up} {
            font-size: rem-calc(24);
            text-align: left;
        }
        @media #{$large-up} {
            font-size: rem-calc(26);
        }
        &:after {
            display: none;
        }
    }
    .actualitat__item {
        display: flex;
        flex-direction: column;
        @media screen and (min-width: 40em) {
            border-bottom: 0;
            padding-bottom: 0;
        }
        p {
            margin-bottom: $spacing * 0.5;
        }
        img {
            order: -1;
            height: auto;
        }
        &--activitat {
            .time {
                order: -1;
                color: #000;
                font-weight: 300;
                font-size: rem-calc(28);
                @media #{$medium-up} {
                    font-size: rem-calc(32);
                }
                @media #{$large-up} {
                    font-size: rem-calc(36);
                }
                span {
                    letter-spacing: -4px;
                    @media #{$medium-up} {
                        letter-spacing: -6px;
                        font-size: rem-calc(60);
                    }
                    @media #{$large-up} {
                        letter-spacing: -8px;
                        font-size: rem-calc(80);
                    }
                }
            }
        }
    }
}
.activitats-container {
    margin-top: $spacing * 2;
}
.actualitat__meta {
    // margin-top: $spacing * 0.5;
    p {
        color: #000;
        font-weight: 600;
        // margin-top: $spacing * 0.5;
    }
    .icon {
        position: relative;
        padding-left: $spacing * 1.125;
        display: inline-block;
        margin-bottom: 0;
        margin-right: $spacing * 0.25;
        &:before {
            position: absolute;
            left: 0;
            top: 0;
        }
        &--loc2 {
            &::before {
                left: 3px;
            }
        }
    }
    .fitxa & {
        margin-bottom: $spacing;
    }
}
.h-noticia-destacada {
    @media #{$medium-up} {
        display: flex;
    }
    img {
        margin-bottom: 0;
        @media #{$medium-up} {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }
    time {
        display: block;
        font-size: 0.875rem;
    }
    h3 {
        font-weight: 800;
    }
    p {
        font-weight: 300;
    }
    > div:last-child {
        padding-top: $spacing * 1.5;
        padding-bottom: $spacing * 1.5;
        > * {
            margin: 0;
            color: #fff;
            + * {
                margin-top: $spacing * 0.5;
            }
        }
    }
    a {
        @include hover;
    }
}
