// caixes destacades
.boxes {
    @media screen and (min-width: 45em) {
        display: flex;
        > div {
            display: flex;
            > div {
                display: flex;
                flex-direction: column;
                justify-content: center;
                > * {
                    margin: 0;
                    + * {
                        margin-top: $spacing * 0.5;
                    }
                }
            }
        }
    }
    > div {
        > div {
            > * {
                margin: 0;
                + * {
                    margin-top: $spacing * 0.5;
                }
            }
        }
    }
    > * + * {
        margin-top: $spacing;
        @media screen and (min-width: 45em) {
            margin-top: 0;
        }
    }
    a {
        @include hover;
    }
    h2 {
        color: #fff;
        font-size: rem-calc(20);
        font-weight: bold;
        @media #{$medium-up} {
            font-size: rem-calc(22);
        }
        @media #{$large-up} {
            font-size: rem-calc(24);
        }
        a {
            color: inherit;
        }
    }
    p {
        font-size: rem-calc(16);
        font-weight: normal;
        line-height: 1.2;
        @media #{$medium-up} {
            font-size: rem-calc(18);
        }
        @media #{$large-up} {
            font-size: rem-calc(20);
        }
    }
}
