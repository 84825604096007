.info {
  h2 {
    font-size: rem-calc(14);
    margin-top: 0;
    margin-bottom: $spacing * 0.75;
    padding-top: $spacing;
    border-top: 1px solid #d7d7d7;
    &:first-child {
      padding-top: 0;
      border-top: 0;
    }
    &:after {
      display: none;
    }
  }
  p,
  ul,
  li {
    color: #000;
    font-size: rem-calc(12);
  }
  li {
    padding-left: 0;
    &:before {
      display: none;
    }
  }
  a {
    color: $link-color;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  .nom-pgm,
  .caps-grup li {
    font-size: rem-calc(14);
  }
  .loc {
    padding-left: $spacing;
    margin-top: -$spacing;
  }
  ul,
  p.icon--sjd,
  .loc {
    font-weight: 600;
  }
}
