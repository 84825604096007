details {
  margin-bottom: $spacing;
  // [open="open"] summary::after {
  //   content: ' \2191';
  // }
}
summary {
  margin-bottom: $spacing;
  cursor: pointer;
  // &::after {
  //   // content: ' \2193';
    
  // }
}
