.etapes-list {
  li {
    color: #fff;
    background-color: #6e7b85;
    &.active {
      // color: #fff;
      // background-color: #5a646b;
    }
  }
}
