.entrevista {
  hr {
    border-color: #fff;
  }
}
.home .section.entrevista {
  @media #{$medium-up} {
    margin-top: $spacing*2;
  }
  .btn--transp {
    background-color: #fff;
    border-color: #000;
    color: #000;
    &:hover,
    &:active {
        color: #fff !important;
        background-color: #000 !important;
    }
  }
}