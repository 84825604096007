.grid-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -$spacing * 0.5;
    > * {
        margin: $spacing * 0.5;
        padding: $spacing;
        position: relative;
        &:before {
            display: none;
        }
        &:last-child {
            margin: $spacing * 0.5;
        }
    }
    &--2 {
        > * {
            width: 100%;
            @media screen and (min-width: 40em) {
                width: calc(50% - 1.5rem);
            }
        }
    }
    &--3 {
        > * {
            width: 100%;
            @media screen and (min-width: 35em) {
                width: calc(50% - 1.5rem);
            }
            @media #{$medium-up} {
                width: calc(33.333% - 1.5rem);
            }
        }
    }
    &--4 {
        > * {
            width: 100%;
            @media screen and (min-width: 35em) {
                width: calc(50% - 1.5rem);
            }
            @media #{$medium-up} {
                width: calc(25% - 1.5rem);
            }
        }
    }
    &--5 {
        > * {
            width: 100%;
            @media screen and (min-width: 35em) {
                width: 50%;
            }
            @media #{$medium-up} {
                width: 25%;
            }
            @media #{$large-up} {
                width: 20%;
            }
        }
    }

    // Recerca
    &--recerca {
        margin: 0;
        justify-content: flex-start;
        h2 {
            font-size: rem-calc(21);
            margin-top: 0;
            margin-bottom: $spacing;
            @media #{$medium-up} {
                // font-size: rem-calc(24);
                // margin-top: $spacing * 0.5;
            }
            // @media #{$large-up} {
            //     font-size: rem-calc(26);
            //     margin-bottom: $spacing * 4;
            // }
        }
        p {
            margin: 0 0 $spacing * 0.5;
            font-size: 0.9375rem;
            @media #{$large-up} {
                margin-top: auto;
            }
        }
        a {
            @include hover;
            color: $body-font-color;
            &.external::after {
                display: none;
            }
        }
        span {
            display: inline-block;
            border: 1px solid;
            border-radius: 3px;
            // padding: 0 $spacing;
            padding: 0 1rem;
            margin-top: $spacing * 0.5;
            @media screen and (min-width: 35em) {
                margin-top: auto;
                margin-right: auto;
            }
            @media #{$large-up} {
                // margin-top: $spacing * 0.5;
                margin-top: auto;
            }
            &:before {
                content: "+";
                display: block;
                // font-size: rem-calc(40);
                font-size: 1.75rem;
                font-weight: 300;
                line-height: 1;
            }
        }
        > * {
            border-bottom: 1px solid #979797;
            padding: 0 0 $spacing;
            margin: 0 0 $spacing;
            position: relative;
            @media screen and (min-width: 35em) {
                display: flex;
                flex-direction: column;
                // padding: $spacing * 4 $spacing $spacing;
                padding: $spacing * 0.5 $spacing $spacing;
                &:last-child {
                    margin: 0 0 $spacing;
                }
            }
            @media #{$medium-up} {
                border-bottom: 0;
                border-right: 1px solid #979797;
                &:last-child:not(:only-child) {
                    border-right: 0;
                }
            }
            // @media #{$large-up} {
            //     padding-top: $spacing * 6;
            // }
            img {
                display: none !important;
                @media screen and (min-width: 35em) {
                    display: inline-block !important;
                }
                margin-bottom: $spacing * 0.5;
                position: absolute;
                left: $spacing;
                top: $spacing;
                // @media #{$medium-up} {
                //     transform: scale(1.75);
                //     transform-origin: 0 0;
                // }
            }
            // &:before {
            //   display: none !important;
            //   @media screen and (min-width: 35em) {
            //     display: inline-block !important;
            //   }
            //   content: "";
            //   background-color: transparent;
            //   margin-bottom: $spacing * 0.5;
            //   left: $spacing;
            //   top: $spacing;
            //   @media #{$large-up} {
            //     font-size: 1.5rem;
            //   }
            // }
            &:last-child {
                margin-left: 0;
                margin-top: 0;
                margin-right: 0;
            }
            // &:nth-child(1):before {
            //   @include sprite("ico-neurociencies");
            // }
            // &:nth-child(2):before {
            //   @include sprite("ico-cancer-pediatric");
            // }
            // &:nth-child(3):before {
            //   @include sprite("ico-immunologia");
            // }
            // &:nth-child(4):before {
            //   @include sprite("ico-tecnologies");
            // }
            // &:nth-child(5):before {
            //   @include sprite("ico-grups-associats");
            // }
            // &:nth-child(5) {
            //   padding-top: 0;
            //   &:before {
            //     display: none;
            //   }
            //   div {
            //     background-color: #fff;
            //     border: 3px solid $secondary-color;
            //     text-align: center;
            //     padding: $spacing;
            //     margin-bottom: $spacing * 0.5;
            //     &:before {
            //       content: "";
            //       display: block;
            //       position: static;
            //       background-color: transparent;
            //       margin: 0 auto $spacing * 0.5;
            //       @include sprite("ico-grups-associats");
            //       @media #{$large-up} {
            //         font-size: 1.5rem;
            //       }
            //     }
            //   }
            //   h2 {
            //     margin-bottom: 0;
            //     @media #{$large-up} {
            //       margin: $spacing * 2 0;
            //     }
            //     a {
            //       color: $secondary-color;
            //     }
            //   }
            // }
            @media screen and (min-width: 35em) {
                &:after {
                    content: "";
                    display: block;
                    background-image: linear-gradient(to bottom, rgba(#186e95, 0.12), transparent);
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    opacity: 0;
                    transition: opacity 0.15s ease-in-out;
                }
                &:hover:after {
                    opacity: 1;
                }
            }
        }
    }

    // Assaigs clínics
    &--espais {
        // font-size: rem-calc(30);
        font-size: rem-calc(24);
        line-height: 1.1;
        text-align: center;
        // @media #{$medium-up} {
        //     font-size: rem-calc(34);
        // }
        // @media #{$large-up} {
        //     font-size: rem-calc(38);
        // }
        // strong {
        //     display: block;
        // }
        > * {
            background-color: $primary-color;
            transition: background-color 0.15s ease-in-out;
            flex-basis: 100%;
            @supports (background-blend-mode: multiply) {
                background-repeat: no-repeat;
                background-size: cover;
                background-blend-mode: multiply;
                // &:first-child {
                //     background-image: url("img/espai-families.jpg");
                // }
                // &:last-child {
                //     background-image: url("img/espai-professionals.jpg");
                // }
            }
            @media screen and (min-width: 35em) {
                flex-basis: 40%;
            }
            @media #{$medium-up} {
                // padding: $spacing * 1.5 $spacing $spacing * 3 $spacing * 2;
                padding: $spacing * 2.5 $spacing;
            }
            &:hover {
                background-color: darken($primary-color, 10%);
            }
        }
        a {
            color: #fff;
            @include hover;
        }
    }

    // Serveis científicotèncics
    &--serveis {
        justify-content: flex-start;
        font-size: rem-calc(20);
        letter-spacing: -0.6px;
        // font-weight: bold;
        line-height: 1.1;
        @media #{$medium-up} {
            font-size: rem-calc(22);
        }
        @media #{$large-up} {
            font-size: rem-calc(24);
        }
        > * {
            background-color: $primary-color;
            transition: background-color 0.15s ease-in-out;
            text-align: center;
            display: flex;
            flex-direction: column;
            @supports (background-blend-mode: multiply) {
                // background-image: url("https://picsum.photos/id/10/600/300?grayscale");
                background-repeat: no-repeat;
                background-size: cover;
                background-blend-mode: multiply;
            }
            @media #{$medium-up} {
                padding: $spacing * 2 $spacing * 1.5;
            }
            &:hover {
                background-color: darken($primary-color, 10%);
            }
        }
        a {
            color: #fff !important;
            @include hover;
            margin: auto;
            &.external::after {
                background: none;
                width: auto;
                height: auto;
                margin: 0;
            }
        }
    }

    // Caixes
    &--boxes {
        text-transform: uppercase;
        letter-spacing: 1.6px;
        font-weight: bold;
        line-height: 1.2;
        @media #{$medium-up} {
            font-size: rem-calc(18);
        }
        @media #{$large-up} {
            font-size: rem-calc(20);
        }
        > * {
            background-color: $primary-color;
            display: flex;
            flex-direction: column;
            text-align: center;
            padding: $spacing;
            opacity: 1;
            transition: opacity 0.15s ease-in-out;
            @media #{$large-up} {
                padding: $spacing * 1.5;
            }
            &:nth-child(4n + 1) {
                background-color: $box-1;
            }
            &:nth-child(4n + 2) {
                background-color: $box-2;
            }
            &:nth-child(4n + 3) {
                background-color: $box-3;
            }
            &:nth-child(4n + 4) {
                background-color: $box-4;
            }
            &:hover {
                opacity: 0.8;
            }
        }
        a {
            @include hover;
            margin: auto;
            color: #fff;
            &.external::after {
                display: none;
            }
        }
    }

    //galeria
    &--galeria {
        li{
            padding:0.5rem;
        }
    }

    a {
        &:after {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            z-index: 1;
        }
    }

    a.external {
        &:after {
            content: "" !important;
            display: block !important;
            position: absolute !important;
            left: 0 !important;
            top: 0 !important;
            bottom: 0 !important;
            right: 0 !important;
            z-index: 1 !important;
            background-image: none !important;
            width: auto;
            height: auto;
        }
    }
}
