.ofertes-list {
    @media #{$medium-up} {
        margin: -$spacing;
    }
    > *,
    > :last-child {
        padding: 0;
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-end;
        background-color: #f2f2f2;
        transition: background-color 0.15s ease-in-out;
        @media #{$medium-up} {
            width: calc(50% - 3rem);
            margin: $spacing;
        }
        &:hover {
            background-color: darken(#f2f2f2, 3%);
        }
    }
    &__panel {
        padding: $spacing;
        display: flex;
        flex-direction: column;
        height: 100%;
        p {
            font-size: 1rem;
            font-weight: 600;
        }
        > span {
            font-size: 1rem;
            font-weight: bold;
            display: block;
            padding-top: $spacing;
            border-top: 1px solid #d4d4d4;
            text-transform: uppercase;
            margin-top: auto;
            &:before {
                content: "+";
                display: inline-block;
                border: 1px solid;
                border-radius: 50%;
                text-align: center;
                margin-right: $spacing * 0.5;
                font-weight: 300;
                font-size: 3rem;
                width: $spacing * 2;
                height: $spacing * 2;
                vertical-align: middle;
                line-height: 1;
                display: inline-flex;
                flex-direction: column;
                justify-content: center;
            }
        }
    }
    &__meta {
        padding: $spacing * 0.5 0;
        background-color: #fff;
        @media screen and (min-width: 55em) {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
        }
        span {
            display: block;
        }
        > span:first-child {
            text-transform: uppercase;
            font-size: 1rem;
        }
        > span:last-child {
            text-transform: uppercase;
            font-size: rem-calc(14);
            strong {
                font-size: rem-calc(16);
                @media #{$medium-up} {
                    font-size: rem-calc(20);
                }
                @media #{$large-up} {
                    font-size: rem-calc(22);
                }
            }
        }
        > span:only-child {
            font-size: rem-calc(16);
            strong {
                font-size: rem-calc(16);
            }
        }
        svg {
            display: inline-block;
            vertical-align: middle;
            margin-right: $spacing * 0.25;
        }
        .open {
            circle:first-child {
                fill: #7ed321;
            }
        }
        .closed {
            circle:last-child {
                fill: #d0021b;
            }
        }
    }
    h2 {
        font-weight: 300;
        font-size: rem-calc(20);
        margin-bottom: $spacing * 1.5;
        @media #{$medium-up} {
            font-size: rem-calc(22);
        }
        @media #{$large-up} {
            font-size: rem-calc(24);
            line-height: 1.4;
        }
    }
    a {
        @include hover;
        color: $body-font-color;
    }
}
