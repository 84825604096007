.site-map {
  &:before {
    content: "";
    display: block;
    height: 3px;
    width: $spacing * 4;
    background-color: $secondary-color;
    margin: 0 auto $spacing * 2;
  }
  h2 {
    font-size: rem-calc(16);
    margin-bottom: $spacing * 0.5;
    &:after {
      display: none;
    }
    a {
      color: #000;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  ul {
    font-size: rem-calc(12);
    font-weight: 600;
    letter-spacing: -0.3px;
    font-style: normal;
    margin: 0;
    a {
      color: #666666;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
