// https://www.bram.us/2016/05/02/prevent-overscroll-bounce-in-ios-mobilesafari-pure-css/
// html,
// body {
//   position: fixed;
//   overflow: hidden;
// }
// .main-wrapper {
//   width: 100vw;
//   height: 100vh;
//   overflow-y: scroll;
//   -webkit-overflow-scrolling: touch; /* enables “momentum” (smooth) scrolling */
// }
html {
    scroll-behavior: smooth;
}
.js body {
    @media screen and (min-width: $sticky-width) {
        padding-top: rem-calc($sticky-height);
    }
}
.text-2-cols {
    margin-bottom: $spacing * 1.5;
    @media screen and (min-width: 43em) {
        column-count: 2;
        column-gap: $spacing*1.5;
    }
}

.multicol {
    @media #{$medium-up} {
        display: flex;
        margin-left: -$spacing*.5;
        margin-right: -$spacing*.5;
    }
    &--1 {
        .multicol__group {
            @media #{$medium-up} {
                flex-basis: 100%;
            }
        }
    }
    &--2 {
        .multicol__group {
            @media screen and (min-width: 43em) {
                flex-basis: 50%;
            }
        }
    }
    &--3 {
        .multicol__group {
            @media #{$medium-up} {
                flex-basis: 33.333%;
            }
        }
    }
    &--4 {
        .multicol__group {
            @media #{$medium-up} {
                flex-basis: 25%;
            }
        }
    }
    &__group {
        margin-bottom: $spacing * 1.5;
        @media #{$medium-up} {
            margin-bottom: 0;
            margin-left: $spacing*.5;
            margin-right: $spacing*.5;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.btn-container {
    padding-top: $spacing * 2;
}

// grups
.col-nom {
    @include grid-column($columns: 12);
    @media screen and (min-width: 40em) {
        @include grid-column($columns: 4);
    }
}
.col-info {
    @include grid-column($columns: 12);
    @media screen and (min-width: 40em) {
        @include grid-column($columns: 8);
    }
    @media screen and (min-width: 60em) {
        @include grid-column($columns: 7, $last-column: true);
    }
}

// equip humà
.col-foto {
    @include grid-column($columns: 12);
    @media screen and (min-width: 40em) {
        @include grid-column($columns: 4);
    }
}
.col-fitxa {
    @include grid-column($columns: 12);
    @media screen and (min-width: 40em) {
        @include grid-column($columns: 8);
    }
    @media screen and (min-width: 60em) {
        @include grid-column($columns: 7, $last-column: true);
    }
}

// portada
.h-col-l,
.h-col-r {
    @include grid-column($columns: 12);
    @media screen and (min-width: 40em) {
        @include grid-column($columns: 6);
    }
}
.btn-col {
    @include grid-column($columns: 12);
    @media screen and (min-width: 40em) {
        @include grid-column($columns: 4);
    }
}

// Stack (https://every-layout.dev/layouts/stack/)
.stack-smaller > * + * {
    margin-top: $spacing * 0.25;
}
.stack-smaller-recursive * + * {
    margin-top: $spacing * 0.25;
}
.stack-small > * + * {
    margin-top: $spacing * 0.5;
}
.stack-small-recursive * + * {
    margin-top: $spacing * 0.5;
}
.stack > * + * {
    margin-top: $spacing;
}
.stack-recursive * + * {
    margin-top: $spacing;
}
.stack-large > * + * {
    margin-top: $spacing * 2;
}
.stack-large-recursive * + * {
    margin-top: $spacing * 2;
}
.stack-larger > * + * {
    margin-top: $spacing * 2.5;
}
.stack-larger-recursive * + * {
    margin-top: $spacing * 2.5;
}
.stack-largest > * + * {
    margin-top: $spacing * 3;
}
.stack-largest-recursive * + * {
    margin-top: $spacing * 3;
}
