.nums-list {
  counter-reset: item;
  margin-left: -$column-gutter*.25 !important;
  margin-right: -$column-gutter*.25 !important;
  &:not(.block-list-2) li {
    padding-left: 0;
    margin-bottom: $spacing*.75;
  }
  li {
    &:before {
      counter-increment: item;
      content: counter(item);
      display: block;
      background-color: $primary-color;
      color: #fff;
      font-weight: 300;
      text-align: center;
      border-radius: 50%;
      width: $spacing * 1.5;
      height: $spacing * 1.5;
      // line-height: $spacing * 1.5;
      line-height: 36px;
      margin-bottom: $spacing * 0.5;
      position: static;
    }
  }
  h3 {
    font-size: rem-calc(18);
    margin-top: 0;
    margin-bottom: $spacing * 0.25;
    font-weight: bold;
    line-height: 1.2;
    color: $body-font-color;
  }
}

.nums-list--2 {
  li {
    position: relative;
    padding-left: $spacing * 2;
    margin-bottom: $spacing;
    @media #{$medium-up} {
      padding-left: $spacing * 2.5;
    }
    &:before {
      counter-increment: item;
      content: counter(item) ".";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      color: #fff;
      font-size: rem-calc(40);
      font-weight: 300;
      background-color: transparent !important;
      width: auto;
      height: auto;
      margin: 0;
      line-height: 1;
      @media #{$medium-up} {
        font-size: rem-calc(50);
      }
      @media #{$large-up} {
        font-size: rem-calc(60);
      }
    }
  }
}
