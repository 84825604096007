.main-header {
  .container {
    @media screen and (min-width: 50em) {
      margin: $spacing 0;
    }
    @media #{$medium-up} {
      margin-top: $spacing * 1.25;
      margin-bottom: $spacing * 1.25;
    }
  }
}
.site-logo {
  @media screen and (min-width: 50em) {
    width: rem-calc(267);
  }
  @media #{$large-up} {
    top: rem-calc(28);
  }
}
