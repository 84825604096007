.galeria-img {
  @include undolist;
  margin: -$spacing * 0.5;
  margin-bottom: $spacing * 1.5;
  font-size: 0;
  display: flex;
  flex-wrap: wrap;
  li {
    // flexbox fallback
    display: inline-block;
    vertical-align: top;

    margin: $spacing * 0.5;
    font-size: 1rem;
    width: calc(50% - 1.5rem);
    @media screen and (min-width: 30em) {
      width: calc(33.333% - 1.5rem);
    }
    @media screen and (min-width: 40em) {
      width: calc(25% - 1.5rem);
    }
    @media #{$medium-up} {
      width: calc(33.333% - 1.5rem);
    }
  }
  img {
    margin-bottom: 0;
  }
}

// ampliació imatge
.mfp-arrow {
  &:before {
    border: 0;
    filter: contrast(0);
    opacity: 1;
  }
  &:after {
    display: none;
  }
}
.mfp-arrow-left {
  &:before {
    @include sprite("fletxa-galeria-anterior");
  }
}
.mfp-arrow-right {
  &:before {
    @include sprite("fletxa-galeria-seguent");
  }
}
