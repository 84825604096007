// a11y-toggle
[data-a11y-toggle]:not([aria-controls]),
[aria-hidden="true"] {
    display: none;
}

/**
 * 1. Globally hiding elements with [aria-hidden="true"] can have rendering
 *    issues with third party integrations. Only known to date is with Google
 *    Maps, in which it prevents the map tiles to render. Therefore it needs to
 *    be resetted inside a Google Maps container.
 *    See: https://github.com/edenspiekermann/a11y-toggle/issues/30
 * 2. Fallback value in case the `initial` value is not supported.
 *    See: http://caniuse.com/#feat=css-initial-value
 */
.map {
    height: 30rem;
    background-color: #eee;
    .sct & {
        margin-bottom: $spacing;
    }
    [aria-hidden="true"] {
        /* 1 */
        display: block; /* 2 */
        display: initial;
    }
}
.equip-list {
    h3 {
        margin-top: 0;
        margin-bottom: 0;
        font-size: rem-calc(16);
        color: #666;
        line-height: 1.4;
    }
    p {
        line-height: 1.4;
        font-size: rem-calc(16);
    }
    strong {
        font-weight: 600;
    }
    a {
        text-decoration: none;
        color: inherit;
        &:hover {
            text-decoration: underline;
        }
    }
    // img {
    //   opacity: 1;
    // }
    // .block-list__wrapper:hover {
    //   img {
    //     opacity: 0.8;
    //   }
    // }
}

wbr {
    @media #{$medium-up} {
        display: none;
    }
}

// map overwrites
.gm-style {
    .gm-style-iw-c {
        // background-color: $primary-color;
        // border-radius: 4px;
        // padding: 0;
        // box-shadow: 0 0 0 0 rgba(143, 79, 79, 0));
        p {
            font-weight: bold;
            font-size: rem-calc(14);
            // color: #fff;
            margin: .5em;
        }
        // button {
        //     top: 1px !important;
        //     right: 1px !important;
        //     img {
        //         filter: invert(100);
        //     }
        // }
    }
    // .gm-style-iw-d {
    //     overflow: unset !important;
    //     padding: 1rem $spacing;
    // }
    // .gm-style-iw-t::after {
    //     background: $primary-color;
    // }
}
// back link
.back {
    color: #fff;
    display: inline-block;
    @include hover;
    font-weight: 600;
    font-size: rem-calc(14);
    margin-bottom: $spacing;
    @media screen and (min-width: 35em) {
        position: absolute;
        right: $column-gutter*.5;
        bottom: $spacing;
        margin-bottom: 0;
    }
    &:before {
        content: '\2190';
    }
}

// arregla estils inline
p[style="text-align: justify;"] {
    text-align: left !important;
}
// arregla p buits
p:empty {
  display: none;
}

// video embed
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  margin-bottom: $spacing;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
