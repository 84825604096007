.etapes-list {
  @include undolist;
  font-size: 0;
  display: flex;
  flex-wrap: wrap;
  margin: -$spacing*.25;
  margin-bottom: $spacing;
  li {
    // color: #fff;
    font-weight: normal;
    font-size: rem-calc(14);
    display: inline-block;
    margin: $spacing*.25;
    margin-right: $spacing*.5;
    padding: $spacing*.25 $spacing*.5;
    white-space: nowrap;
    background-color: #80c1f0;
    &:last-child {
      margin-bottom: $spacing*.25;
    }
    &.active {
      // color: #fff;
      // font-weight: bold;
      background-color: $primary-color;
    }
    strong {
      font-weight: bold;
    }
    @supports (clip-path: circle(50%)) {
      &::after {
        content: '';
        display: block;
        position: absolute;
        right: -8px;
        top: 0;
        width: 8px;
        height: 100%;
        clip-path: polygon(0 0, 8px 50%, 0 100%);
        background-color: inherit;
      }
    }
  }
}
