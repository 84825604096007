@font-face {
  font-family: "Open Sans Condensed";
  font-style: normal;
  font-weight: 300;
  src: url(../../fonts/Open-Sans-Condensed.eot);
  src: local("Open Sans Condensed"), local("Open Sans Condensed Light"), local("OpenSansCondensed-Light"),
    url(../../fonts/Open-Sans-Condensed.eot?#iefix) format("embedded-opentype"),
    url(../../fonts/Open-Sans-Condensed.ttf) format("truetype"),
    url(../../fonts/Open-Sans-Condensed.woff) format("woff"), url(../../fonts/Open-Sans-Condensed.woff2) format("woff2"),
    url(../../fonts/Open-Sans-Condensed.svg#OpenSansCondensed) format("svg");
  font-display: swap;
}
