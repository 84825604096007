.main-header {
    padding-top: $spacing * 3.5;
    position: relative;
    @media screen and (min-width: 50em) {
        position: static;
        padding-top: 0;
    }
    @media screen and (min-width: $sticky-width) {
        background: #fff;
        height: rem-calc($sticky-height);
        transition-property: top;
        transition-duration: 0.2s;
        transition-timing-function: ease-in-out;
        .js & {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 2;
        }
        &.sticky {
            top: rem-calc(-$sticky-height);
        }
    }
    .nav-container {
        @media #{$medium-up} {
            position: relative;
        }
    }
    .container {
        @media screen and (min-width: 50em) {
            margin: $spacing 0 $spacing * 2 0;
            padding-left: $column-gutter * 0.5;
            padding-right: $column-gutter * 0.5;
        }
        @media #{$medium-up} {
            margin-top: $spacing * 2;
        }
    }
}
.site-logo {
    text-align: center;
    margin: 0 auto $spacing;
    line-height: 1;
    font-size: rem-calc(16);
    width: rem-calc(253);
    height: rem-calc(39);
    @media #{$medium-up} {
        text-align: left;
        margin: 0;
        position: absolute;
        left: $column-gutter * 0.5;
        top: -2rem;
    }
    a {
        display: block;
    }
    img {
        margin-bottom: 0;
    }
}
.tools-wrapper {
    @media screen and (min-width: 50em) {
        background-color: #eee;
        background-image: linear-gradient(to right, #ffffff 40%, #eeeeee 47%);
        padding: $spacing * 0.75 0;
        height: rem-calc(68);
    }
}
.tools-container {
    @media screen and (min-width: 50em) {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: rem-calc(14);

        /*flexbox fallback*/
        .ie9 & {
            text-align: right;
            > * {
                display: inline-block;
            }
        }
        /*/flexbox fallback*/
    }
}
.tools-container-mobile {
    display: block;
    @media screen and (min-width: 50em) {
        display: none;
    }
}
.tools-container-desktop {
    display: none;
    @media screen and (min-width: 50em) {
        display: block;
    }
}
.site-menu .col a {
    display: block;
    text-align: center;
    color: #fff;
    background-color: $secondary-color;
    padding: $spacing * 0.5 $spacing;
    border-radius: 3px;
    transition: background-color 0.1s ease-in-out;
    display: inline-block;
    @media screen and (min-width: 50em) {
        padding: $spacing * 0.25 $spacing * 0.5;
    }
    &:hover,
    &:focus {
        background-color: #fff;
        color: $secondary-color;
        border-color: $secondary-color;
    }
}
a.contacte {
    color: #000;
    letter-spacing: -0.4px;
    text-decoration: none;
    margin: 0 $spacing * 1.5 $spacing $spacing;
    float: right;
    @media screen and (min-width: 50em) {
        margin: 0 0 0 $spacing;
    }
    &:hover {
        text-decoration: underline;
    }
    &:before {
        content: "";
        @include sprite("contact");
        margin-right: $spacing * 0.25;
    }
}

// hack per Safari per evitar aquest problema: https://www.smashingmagazine.com/2018/08/scroll-bouncing-websites/ Seria millor trobar una altra solució però de moment això és el que hi ha.
_::-webkit-full-page-media,
_:future,
:root .main-header.sticky {
    top: 0;
}
