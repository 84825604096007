.sub-nav-3 {
  @include undolist;
  font-size: rem-calc(14);
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.78px;
  margin-bottom: $spacing*2;
  li {
    margin-bottom: 0;
    display: inline-block;
    @media #{$medium-up} {
      display: block;
    }
  }
  li.active a,
  a:hover {
    color: #fff;
    background: darken(
      $primary-color,
      4%
    ); /*enfosquit perquè passi validació contrast*/
    @media #{$medium-up} {
      position: relative;
      &:before {
        content: "";
        display: block;
        position: absolute;
        left: -200%;
        top: 0;
        bottom: 0;
        width: 200%;
        background-color: inherit;
      }
    }
  }
  a {
    text-decoration: none;
    display: block;
    color: $body-font-color;
    padding: $spacing*.25 $spacing*.5;
    margin-bottom: 1px;
    @media #{$medium-up} {
      padding: $spacing * 0.5;
      padding-right: $spacing * 3;
    }
  }
}
