.head {
  background: darken(
    $primary-color,
    4%
  ); /*enfosquit perquè passi validació contrast*/
  padding: $spacing 0;
  padding-bottom: 0;
  @media screen and (min-width: 40em) {
    padding-top: $spacing*2;
  }
  @media #{$medium-up} {
    padding: $spacing * 3 0 $spacing * 1.5;
    padding-bottom: 0;
  }
  a {
    color: #fff;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
.head--1 {
  display: inline-block;
  color: #fff;
  font-weight: 300;
  font-size: rem-calc(20);
  text-decoration: none;
  letter-spacing: -0.5px;
  line-height: 1.2;
  margin-bottom: $spacing*.5;
  @media screen and (min-width: 40em) {
    position: absolute;
    left: $column-gutter * 0.5;
    top: -1.5em;
    margin-bottom: 0;
  }
  @media #{$medium-up} {
    font-size: rem-calc(30);
  }
}
.head--2 {
  display: block;
  color: #fff;
  margin-bottom: 0;
  font-weight: bold;
  font-size: rem-calc(20);
  line-height: 1.1;
  letter-spacing: -0.5px;
  margin-bottom: $spacing;
  @media #{$medium-up} {
    font-size: rem-calc(30);
  }
}
.head--3 {
  display: block;
  color: #fff;
  margin-bottom: 0;
  // font-weight: 800;
  font-weight: 700;
  font-size: rem-calc(32);
  line-height: 1.1;
  letter-spacing: -0.96px;
  margin-bottom: $spacing;
  @media #{$medium-up} {
    // font-size: rem-calc(40);
    font-size: rem-calc(36);
  }
  // @media #{$large-up} {
  //   font-size: rem-calc(63);
  // }
  + p {
    color: #fff;
    margin-bottom: $spacing * 1.5;
    @media #{$medium-up} {
      width: 50%;
      margin-bottom: $spacing * 2;
    }
  }
}
// capçalera amb imatge de fons
.head--bg {
  @supports (background-blend-mode: multiply) {
    background-color: darken($primary-color, 15%);
    // background-image: url("https://picsum.photos/1600/420?grayscale");
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: multiply;
  }
  &--2 {
    background-color: #444;
  }
}
