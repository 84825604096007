.top-header {
  color: #fff;
  background-color: #666;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  margin-bottom: $spacing * 2;
  @media #{$medium-up} {
    margin-bottom: $spacing * 3;
  }
  &--microscopia {
    background-image: url("img/bg-plataformes-microscopia.jpg");
    @media #{$retina} {
      background-image: url("img/bg-plataformes-microscopia@2x.jpg");
    }
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: #666;
    background-color: rgba(0, 0, 0, 0.6);
  }
  h2 {
    color: #fff;
    margin: $spacing * 2 0;
    @media #{$medium-up} {
      margin: $spacing * 3 0;
    }
    @media #{$large-up} {
      margin: $spacing * 4 0;
    }
  }
}
