.noms-list {
    @include undolist;
    font-style: normal;
    margin: 0;
    > * {
        margin: 0;
        + * {
            margin-top: $spacing * 0.5;
        }
    }
}
