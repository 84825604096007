.grups-list {
    @include undolist;
    @include block-grid(1);
    display: flex !important;
    flex-wrap: wrap;
    @media screen and (min-width: 30em) {
        @include block-grid(2);
    }
    @media #{$medium-up} {
        @include block-grid(3);
    }
    @media #{$xlarge-up} {
        @include block-grid(4);
    }
    > li {
        position: relative;
        margin-bottom: $spacing;
        &:after {
            content: '';
            display: block;
            height: 1px;
            background-color: #d7d7d7;
            position: absolute;
            left: $block-grid-default-spacing*.5;
            right: $block-grid-default-spacing*.5;
            bottom: 0;
        }
        &:last-child {
            margin-bottom: $spacing;   
        }
    }
    h3 {
        font-weight: 300;
        font-size: 1rem;
        hyphens: auto;
        @media screen and (min-width: 30em) {
            font-size: rem-calc(18);
        }
        a {
            display: block;
            color: #fff;
            background: darken(
                $primary-color,
                4%
            ); /*enfosquit perquè passi validació contrast*/
            padding: $spacing;
            text-decoration: none;
            transition: background-color 0.15s ease-out;
            @media screen and (min-width: 30em) {
                min-height: $spacing * 5;
            }
            @media #{$medium-up} {
                min-height: $spacing * 7;
            }
            @media #{$large-up} {
                min-height: $spacing * 8;
            }
            &:hover,
            &:focus {
                background-color: $primary-color;
                text-decoration: underline;
            }
        }
    }
}
