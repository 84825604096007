.entitat {
  padding-left: $column-gutter * 0.5;
  padding-right: $column-gutter * 0.5;
  margin-bottom: $spacing * 2;
  @media #{$medium-up} {
    border-bottom: 0;
    margin-bottom: $spacing * 3;
  }
  h2,
  &--ext h3 {
    letter-spacing: -0.65px;
    font-weight: 800;
    font-size: rem-calc(22);
    margin-bottom: $spacing;
    text-transform: none !important;
    @media #{$medium-up} {
      font-size: rem-calc(24);
      margin-bottom: $spacing * 1.5;
    }
    @media #{$large-up} {
      font-size: rem-calc(26);
      margin-bottom: $spacing * 2;
    }
  }
  h3,
  &--ext h4 {
    margin-top: 0;
    margin-bottom: $spacing * 0.5;
    text-transform: uppercase;
  }
  &--ext h4 {
    font-size: rem-calc(18);
    font-weight: 600;
    line-height: 1.2;
  }
  a {
    @include hover;
  }
  img {
    @media #{$medium-up} {
      float: right;
      margin-right: $column-gutter;
      margin-bottom: 0;
    }
  }
  &--ext h3 {
    margin-bottom: $spacing;
    @media #{$medium-up} {
      margin-bottom: $spacing * 1.5;
    }
    @media #{$large-up} {
      margin-bottom: $spacing * 2;
    }
  }
  > div > div > div {
    @media #{$medium-up} {
      padding-right: $column-gutter !important;
    }
  }

}
h2.entitat-apartat {
  // border-bottom: 2px solid;
  // padding-bottom: $spacing*.5;
}