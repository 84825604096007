.houdiniForm {
    margin-bottom: $spacing *2;
    fieldset {
        border: 0;
        margin: 0;
        padding: 0;
    }
    legend {
        font-weight: bold;
        margin-bottom: $spacing;
    }
    div {
        margin-bottom: $spacing * 0.75;
    }
    section{
        margin-bottom: $spacing * 0.75;
    }
    label,
    span.label {
        display: block;
        margin-bottom: $spacing * 0.25;
        font-size: rem-calc(14);
        text-transform: uppercase;
        letter-spacing: 0.78px;
        font-weight: 600;
        &.simple-label {
            text-transform: none;
            letter-spacing: 0;
            font-weight: normal;
        }
        &.error {
            text-transform: none;
            letter-spacing: 0;
            font-weight: normal;
            color: #d60000;
            margin-top: $spacing * 0.25;
        }
        .info {
            .js & {
                display: none;
            }
        }
    }
    .simple-label label {
        @extend .simple-label;
    }
    input[type="text"],
    input[type="tel"],
    input[type="email"],
    input[type="password"],
    // select,
    textarea {
        border-radius: 8px;
        color: #313131;
        background-color: #fbfcfc;
        border: 1px solid #999;
        padding: $spacing * 0.75 $spacing * 0.75;
        width: 100%;
        height: rem-calc(60);
        box-shadow: none;
        transition: background-color 0.1s;
        &.error {
            border-color: #f00;
        }
        &:focus {
            background-color: #eee;
        }
    }
    textarea {
        height: $spacing * 10;
    }
    input[type="checkbox"],
    input[type="radio"] {
        width: rem-calc(30);
        height: rem-calc(30);
        background-color: #fbfcfc;
        border: 1px solid #999;
        vertical-align: middle;
        display: inline-block;
        margin-right: $spacing * 0.25;
        border-radius: 8px;
        box-shadow: none;
        &:focus {
            outline: 1px dotted #000;
        }
        &.error {
            border-color: #d60000;
        }
    }
    input[type="checkbox"]:checked,
    input[type="radio"]:checked {
        position: relative;
        //   background: none;
    }
    input[type="checkbox"]:checked::after,
    input[type="radio"]:checked::after {
        position: absolute;
        top: 0.5rem;
        left: 0.4rem;
        content: "";
        width: 1rem;
        height: 0.5rem;
        border: 2px solid $primary-color;
        border-right: none;
        border-top: none;
        transform: rotate(-45deg);
    }
    button {
        margin-bottom: 0;
        border-radius: 8px;
    }

    // select
    select {
        border-radius: 8px;
        color: #313131;
        background-color: #fbfcfc;
        border: 1px solid #999;
        padding: $spacing * 0.75 $spacing * 2 $spacing * 0.75 $spacing * 0.75;
        width: 100%;
        height: rem-calc(60);
        box-shadow: none;
        transition: background-color 0.1s;
        display: block;
        box-sizing: border-box;
        appearance: none;
        background-image: url('img/ic_expand_more_24px.svg');
        background-repeat: no-repeat;
        background-position: 95% 50%;
        background-position: calc(100% - 1.5rem) 50%;
        &.error {
            border-color: #f00;
        }
        &:focus {
            background-color: #eee;
        }
    }
    select::-ms-expand {
        display: none;
    }
    select:focus {
        outline: 1px dotted #000;
    }
    // select option {
    //     font-weight: normal;
    // }

    &.cercador {
        button {
            @media #{$medium-up} {
                padding-left: $spacing;
                padding-right: $spacing;
                margin-top: 1.9rem;
            }
            &:before {
                content: "";
                @include sprite("ico-lupa-cap-copy");
                display: inline-block;
                vertical-align: middle;
                margin-right: $spacing * 0.5;
                font-size: 1rem;
                transition: transform 0.15s;
            }
            &:hover {
                color: #fff;
                background-color: $primary-color;
                &:before {
                    transform: scale(1.1);
                }
            }
        }
        // cercador equip humà
        &--equip {
            @media #{$medium-up} {
                margin-bottom: 0;
                .row > div {
                    margin-bottom: 0;
                }
            }
            button {
                @media #{$medium-up} {
                    padding-left: $spacing;
                    padding-right: $spacing;
                    margin-top: 1.9rem;
                }
            }
        }
        // cercador convocatòries
        &--conv {
            .labels-group {
                margin-bottom: 0;
                @media #{$medium-up} {
                    margin-top: $spacing;
                }
                label {
                    display: inline-block;
                    margin-right: $spacing * 0.5;
                }
            }
            .btn-container {
                padding-top: 0;
                @media #{$medium-up} {
                    padding-top: 1.9rem;
                    text-align: right;
                }
            }
            button {
                margin-top: 0;
            }
        }
    }
}
