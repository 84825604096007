.dades {
  color: darken($primary-color,7%);
  li {
    margin-bottom: $spacing * 0.5;
    dl,
    p {
      margin-bottom: 0;
    }
  }
  h3 {
    font-size: rem-calc(14);
    margin: 0;
    line-height: 1.6;
  }
}
