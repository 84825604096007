.top-img-container {
    width: 100%;
    height: rem-calc(200);
    @media #{$medium-up} {
        height: rem-calc(340);
    }
    @media #{$large-up} {
        // height: rem-calc(495);
        // height: rem-calc(400);
    }
    &--big {
        @media #{$medium-up} {
            height: rem-calc(400);
        }
        @media #{$large-up} {
            height: rem-calc(994);
        }
    }
    &--full {
        height: rem-calc(300);
        height: auto;
        overflow: hidden;
        @media #{$medium-up} {
            height: rem-calc(400);
            position: relative;
        }
        @media #{$large-up} {
            height: rem-calc(550);
        }
        .row {
            @media #{$medium-up} {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
        .text-box {
            h2 {
                font-size: rem-calc(20);
                font-weight: bold;
                margin-bottom: $spacing * 0.25;
                @media #{$medium-up} {
                    font-size: rem-calc(26);
                }
                @media #{$large-up} {
                    font-size: rem-calc(28);
                }
                @media #{$xlarge-up} {
                    font-size: rem-calc(32);
                }
            }
            p {
                font-weight: normal;
                margin-bottom: 0;
            }
        }
    }
}
.top-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: left top;
    // .top-img-container--big & {
    //   object-position: center top;
    // }
}
