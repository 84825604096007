.section {
    padding: $spacing * 2 0;
    overflow-x: hidden; /*per evitar overflow lateral a llistat d'"Equip humà"*/
    @media #{$medium-up} {
        padding: $spacing * 3 0;
    }
    &--top-img {
        padding-top: 0;
    }
    &--top {
        padding-top: 0;
    }
    &--bg {
        background-color: #f1f1f1;
        a:not(.btn) {
            color: darken($primary-color,7%);
        }
    }
    &--bg-2 {
        color: #fff;
        background-color: darken($primary-color, 5%);
        a:not(.btn) {
            color: inherit;
        }
    }
    &--bg-3 {
        color: #fff;
        background-color: #313131;
    }
    &--info {
        h2 {
            font-size: rem-calc(22);
            font-weight: 800;
            margin: 0;
            @media #{$medium-up} {
                font-size: rem-calc(24);
            }
            @media #{$large-up} {
                font-size: rem-calc(26);
            }
            &:after {
                content: "";
                display: block;
                height: 2px;
                width: $spacing * 10;
                background-color: #313131;
                margin: $spacing 0;
            }
        }
        h3 {
            margin: 0 0 $spacing * 1.5;
            font-size: rem-calc(34);
            font-weight: 300;
            @media #{$medium-up} {
                font-size: rem-calc(36);
            }
            @media #{$large-up} {
                font-size: rem-calc(38);
            }
        }
        h4 {
            text-transform: uppercase;
            margin: 0 0 $spacing;
            font-size: rem-calc(16);
            font-weight: bold;
            @media #{$medium-up} {
                font-size: rem-calc(18);
            }
        }
        img {
            border-radius: 50%;
            margin: 0 auto;
            display: block;
            @media #{$large-up} {
                margin-right: 0;
            }
        }
    }
}
.alternate-sections {
    >*:nth-child(even) {
        background-color: #f1f1f1;
        a:not(.btn) {
            color: darken($primary-color,7%);
        }
    }
}

// Destacat ample 100%. Classe per a l'editor visual:
.dest-fons {
    @extend .u-wider;
    @extend .section;
    @extend .section--bg;
    margin-bottom: $spacing;
    > div > div > * {
        margin: 0;
        + * {
            margin-top: $spacing;
        }
    }
}
