// @include typi-init;
html {
    font-size: 100%;
    line-height: 1.5;
}
body {
    background: $body-bg;
    color: $body-font-color;
    font-family: $body-font-family;
    font-weight: 300;
}
h1,
.t1 {
    display: block;
    color: #000;
    font-weight: 800;
    font-size: rem-calc(22);
    line-height: 1.1;
    margin-bottom: $spacing;
    letter-spacing: -0.65px;
    @media #{$medium-up} {
        font-size: rem-calc(24);
    }
    @media #{$large-up} {
        font-size: rem-calc(26);
    }
    &.nom-grup {
        margin-bottom: $spacing * 1.5 !important;
        font-weight: bold;
        font-size: rem-calc(24);
        line-height: 1.3;
        @media #{$medium-up} {
            font-size: rem-calc(30);
        }
    }
    &.tipus2 {
        font-weight: 300;
        font-size: rem-calc(30);
        letter-spacing: 0;
        @media #{$medium-up} {
            font-size: rem-calc(40);
        }
        @media #{$large-up} {
            font-size: rem-calc(50);
        }
    }
    // Serveis cientificotècnics
    .sct & {
        font-weight: 300;
        font-size: rem-calc(30);
        letter-spacing: 0;
        @media #{$medium-up} {
            font-size: rem-calc(35);
        }
        @media #{$large-up} {
            font-size: rem-calc(45);
        }
    }
}
h2,
.t2 {
    // font-size: rem-calc(18);
    font-size: rem-calc(22);
    font-weight: 600;
    color: $body-font-color;
    line-height: 1.2;
    // margin-top: $spacing * 3;
    // margin-bottom: $spacing * 2;
    margin-top: $spacing * 2;
    margin-bottom: $spacing;
    @media #{$medium-up} {
        // font-size: rem-calc(20);
        font-size: rem-calc(24);
    }
    @media #{$large-up} {
        font-size: rem-calc(26);
    }
    &:first-child {
        margin-top: 0;
    }
    // &:after {
    //   content: "";
    //   display: block;
    //   height: 2px;
    //   width: $spacing * 7;
    //   background-color: #000;
    //   margin-top: $spacing * 0.5;
    // }
    &.simple {
        font-size: rem-calc(18);
        @media #{$medium-up} {
            font-size: rem-calc(20);
        }
        // &:after {
        //   display: none;
        // }
    }
    .section--bg-2 &,
    .section--bg-3 & {
        color: #fff;
    }
    .multicol__group & {
        margin-bottom: $spacing;
    }
    // Serveis cientificotècnics
    .sct & {
        margin-top: 0;
        margin-bottom: $spacing;
    }
}
h3,
.t3 {
    color: #000;
    font-size: rem-calc(18);
    font-weight: 600;
    // margin-top: $spacing * 2;
    margin-bottom: $spacing;
    line-height: 1.2;
    .section--bg-2 & {
        color: #fff;
        margin-top: $spacing * 3;
        margin-bottom: $spacing * 1.5;
        // &:after {
        //   content: "";
        //   display: block;
        //   height: 2px;
        //   width: $spacing * 9;
        //   background-color: #000;
        //   margin-top: $spacing * 0.5;
        // }
    }
    .multicol__group & {
        margin-top: 0;
        margin-bottom: $spacing;
    }
}
h4,
.t4 {
    color: #000;
    font-size: rem-calc(16);
    font-weight: 600;
    // margin-bottom: $spacing * 0.5;
    margin-bottom: $spacing;
    line-height: 1.2;
}
h5,
.t5 {
    margin-bottom: $spacing * 0.5;
    line-height: 1.2;
}
p,
ol,
ul,
dl {
    font-size: rem-calc(16);
    line-height: 1.6;
    @media #{$medium-up} {
        font-size: rem-calc(17);
    }

    // classes per element contenidor
    .smaller & {
        font-size: rem-calc(12);
    }
    .small & {
        font-size: rem-calc(14);
    }
    .normal & {
        font-size: rem-calc(16);
    }
    .big & {
        font-size: rem-calc(18);
    }
    .bigger & {
        font-size: rem-calc(20);
        line-height: 1.4;
        @media #{$medium-up} {
            font-size: rem-calc(22);
        }
    }
    .biggest & {
        font-size: rem-calc(24);
        line-height: 1.4;
        @media #{$medium-up} {
            font-size: rem-calc(26);
        }
    }

    // classes directes a l'element de text
    &.smaller {
        font-size: rem-calc(12) !important;
    }
    &.small {
        font-size: rem-calc(14) !important;
    }
    &.normal {
        font-size: rem-calc(16) !important;
    }
    &.big {
        font-size: rem-calc(18) !important;
    }
    &.bigger {
        font-size: rem-calc(20) !important;
        line-height: 1.4 !important;
        @media #{$medium-up} {
            font-size: rem-calc(22) !important;
        }
    }
    &.biggest {
        font-size: rem-calc(24) !important;
        line-height: 1.4 !important;
        @media #{$medium-up} {
            font-size: rem-calc(26) !important;
        }
    }
}

p,
ol,
ul,
dl,
figure,
img,
form,
hr,
table {
    margin: 0 0 $spacing;
    // font-size: rem-calc(15);
}

ul {
    list-style: none;
    main & {
        li {
            position: relative;
            padding-left: $spacing;
            margin-bottom: $spacing * 0.25;
            &:last-child {
                margin-bottom: 0;
            }
            &::before {
                content: "";
                display: block;
                width: $spacing * 0.25;
                height: $spacing * 0.25;
                position: absolute;
                left: 0;
                top: 0.65em;
                background-color: $body-font-color;
            }
            ul {
                font-style: italic;
                font-size: rem-calc(14);
                margin-top: $spacing * 0.5;
                margin-bottom: $spacing * 0.5;
                margin-left: $spacing;
                li {
                    padding-left: $spacing * 0.5;
                    &::before {
                        width: $spacing * 0.125;
                        height: $spacing * 0.125;
                        left: 0;
                        top: 0.7em;
                    }
                }
            }

            // &:before {
            //   content: "";
            //   display: block;
            //   height: 1px;
            //   width: $spacing * 0.5;
            //   background-color: $primary-color;
            //   position: absolute;
            //   left: 0;
            //   top: 0.75em;
            //   @media #{$medium-up} {
            //     width: $spacing;
            //   }
            // }
        }
    }
    .section--bg-2 & {
        li {
            &:before {
                background-color: #fff;
            }
        }
    }
}

// llista simple, sense klanders
ul,
ol {
    font-size: 1rem;
    &.simple-list {
        list-style: none;
        li {
            padding-left: 0;
            &:before {
                display: none;
            }
        }
    }
}

// llista amb separadors
.borders-list {
    @include undolist;
    // font-weight: 600;
    li {
        margin-bottom: 0;
        padding: $spacing * 0.5 0 $spacing * 0.5 $spacing;
        border-bottom: 1px solid $primary-color;
        position: relative;
        &::before {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: 1.2em;
            width: $spacing * 0.5;
            height: $spacing * 0.5;
            background-color: $primary-color;
        }
    }
    a {
        @include hover;
    }
}

// llistat d'enllaços
.links-list {
    @include undolist;
    font-weight: 600;
    font-size: rem-calc(14);
    li {
        margin-bottom: 0;
        padding: $spacing * 0.5 0;
        border-bottom: 1px solid #d1d1d1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:after {
            content: "";
            display: block;
            @include sprite("external-link");
            font-size: 1rem;
            margin-right: $spacing * 0.25;
            margin-left: $spacing;
            flex-shrink: 0;
            flex-grow: 0;
        }
        &:first-child {
            padding-top: 0;
        }
    }
    a {
        @include hover;
        &.external::after {
            display: none;
        }
    }
}

ol {
    padding-left: $spacing;
}

.dl-inline {
    dt,
    dd {
        margin: 0;
        padding: 0;
    }
    dt {
        float: left;
        margin-right: 0.3em;
    }
}
.dl-info {
    div {
        padding-bottom: $spacing * 0.5;
        margin-bottom: $spacing * 0.5;
        border-bottom: 1px solid #d7d7d7;
        @media #{$medium-up} {
            display: flex;
            flex-wrap: wrap;
        }
    }
    dt {
        margin-bottom: $spacing * 0.5;
        font-size: rem-calc(14);
        font-weight: bold;
        @media #{$medium-up} {
            width: 33%;
        }
        &:after {
            content: "";
            display: block;
            width: $spacing * 4;
            height: 1px;
            background-color: #313131;
            margin-top: $spacing * 0.5;
        }
    }
    dd {
        margin-left: 0;
        font-size: rem-calc(16);
        @media #{$medium-up} {
            margin-left: auto;
            width: 66%;
        }
    }
    h2 {
        margin: 0 0 $spacing;
        font-size: 1rem;
    }
    p,
    ul {
        font-size: rem-calc(16);
    }
    ul {
        margin-left: $spacing;
        font-style: italic;
    }
}

figure {
    img {
        margin-bottom: 0;
    }
}
figcaption {
    font-size: rem-calc(12);
    margin-top: $spacing * 0.5;
    font-style: italic;
    @media #{$medium-up} {
        font-size: rem-calc(14);
    }
}

::selection {
    color: #fff;
    background-color: $primary-color;
}
strong,
b {
    font-weight: 600;
}
// em,
// i {
// }

.upp {
    text-transform: uppercase;
}

// links underline
a {
    color: $link-color;
    &:hover {
        text-decoration: none;
    }
    &.external {
        main & {
            &:after {
                content: "";
                display: inline-block;
                vertical-align: middle;
                @include sprite("external-link");
                font-size: 1rem;
                margin-left: $spacing * 0.25;
                opacity: 0.3;
            }
        }
        .destacat & {
            &:after {
                filter: invert(100);
            }
        }
    }
    &.gallery-item {
        &:after {
            display: none !important;
        }
    }
    &[href^="tel"] {
        &::after {
            display: none !important;
        }
    }
}
a,
button,
input {
    &:focus {
        outline: none;
        outline: 1px dotted black;
        // box-shadow: inset 0 0 0 4px lighten($primary-color,20%);
        .sub-nav-2-container & {
            outline-color: #fff;
        }
    }
}

p.dest,
.dest p {
    color: #000;
    padding: $spacing 0;
    border: solid $primary-color;
    border-width: 1px 0;
    font-weight: 300;
    font-size: rem-calc(18);
    text-align: center;
    line-height: 1.4;
    @media #{$medium-up} {
        font-size: rem-calc(24);
        border-width: 2px 0;
    }
    strong {
        font-weight: bold;
    }
}
.dest-2,
.dest-3 {
    padding: $spacing $spacing * 2;
    text-align: center;
    @media #{$medium-up} {
        padding-left: $spacing * 3;
        padding-right: $spacing * 3;
    }
}
.dest-2 {
    border: 1px solid $primary-color;
    color: $primary-color;
    font-weight: bold;
}
.dest-3 {
    color: #fff;
    background-color: $primary-color;
    background-image: linear-gradient(to right, darken($primary-color, 5%), $primary-color);
    a {
        color: inherit;
    }
}
.dest-4 {
    font-family: "Open Sans Condensed", $body-font-family;
    color: #000;
    padding: $spacing $spacing * 0.5;
    border: solid $body-font-color;
    border-width: 1px 0;
    font-weight: 300;
    font-size: rem-calc(20);
    text-align: center;
    line-height: 1.4;
    @media #{$medium-up} {
        font-size: rem-calc(24);
    }
    @media #{$large-up} {
        font-size: rem-calc(28);
    }
    .fitxa & {
        text-align: left;
    }
    p {
        margin-bottom: 0;
        font-size: 1em;
    }
}
.dest-5 p {
    font-family: "Open Sans Condensed", $body-font-family;
    color: #000;
    font-weight: 300;
    font-size: rem-calc(20);
    line-height: 1.4;
    @media #{$medium-up} {
        font-size: rem-calc(24);
    }
    @media #{$large-up} {
        font-size: rem-calc(28);
    }
    &::before {
        content: "";
        display: block;
        width: $spacing * 8;
        height: 1px;
        background-color: #000;
        margin-bottom: $spacing;
        @media #{$medium-up} {
            height: 2px;
        }
    }
}
p.pregunta {
    font-weight: bold;
}

hr {
    border: 0;
    border-top: 1px solid #cccccc;
}
table {
    width: 100%;
    th,
    td {
        text-align: left;
        padding: $spacing * 0.5 $spacing * 0.75;
    }
    thead {
        th {
            font-weight: bold;
            color: #666;
            padding-left: 0;
            padding-right: 0;
        }
    }
    tbody {
        th {
            font-weight: 600;
            text-align: left;
            vertical-align: top;
        }
        td {
            font-weight: normal;
            vertical-align: top;
        }
        tr > * {
            border-bottom: $spacing * 0.25 solid #fff;
            &:nth-child(odd) {
                background-color: lighten($primary-color, 45%);
            }
            &:nth-child(even) {
                background-color: lighten($primary-color, 50%);
            }
        }
    }
}
table.simple-table {
    th {
        font-size: rem-calc(14);
        vertical-align: top;
        font-weight: normal;
    }
    thead th {
        color: #000;
    }
    thead {
        text-align: left;
    }
    tbody {
        th {
            font-weight: normal;
        }
        tr > * {
            border-bottom: 0;
            &:nth-child(odd) {
                background-color: transparent;
            }
            &:nth-child(even) {
                background-color: transparent;
            }
        }
    }
    td {
        vertical-align: top;
        font-size: rem-calc(14);
    }
    th,
    td {
        border-top: 1px solid #ccc;
        padding: $spacing * 0.5 $spacing * 0.75;
        background-color: transparent;
    }
    ul,
    ol,
    p {
        margin-bottom: 0;
        font-size: rem-calc(14);
    }
    .inline-list li {
        font-size: rem-calc(14);
        margin-bottom: $spacing * 0.5;
    }
    a {
        color: $primary-color;
    }
}
.table-container {
    width: 100%;
    overflow-y: auto;
    _overflow: auto;
    margin: 0 0 1em;
}
.table-container::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 14px;
    height: 14px;
}

.table-container::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 3px solid #fff;
    background-color: rgba(0, 0, 0, 0.3);
}
.inline-list {
    font-size: 0;
    margin-bottom: 0;
    margin-left: -$spacing * 0.5;
    margin-right: -$spacing * 0.5;
    li {
        font-size: 1rem;
        display: inline-block;
        vertical-align: middle;
        margin: 0 $spacing * 0.5 $spacing;
        &:last-child {
            margin-bottom: $spacing;
        }
    }
    &--logos {
        li {
            margin: 0 $spacing * 1.5 $spacing * 2;
            &:last-child {
                margin-bottom: $spacing * 2;
            }
        }
    }
    img {
        margin-bottom: 0;
    }
    .info & {
        li a::after {
            display: none;
        }
    }
}
.intro p,
p.intro {
    font-weight: 300;
    font-size: rem-calc(18);
    @media #{$medium-up} {
        font-size: rem-calc(22);
    }
    @media #{$large-up} {
        font-size: rem-calc(24);
        line-height: 1.4;
    }
    + p.biggest {
        font-size: rem-calc(35);
        @media #{$medium-up} {
            font-size: rem-calc(40);
        }
        @media #{$large-up} {
            font-size: rem-calc(50);
        }
    }
}
p.lead {
    font-weight: bold;
    .entrevistes & {
        font-weight: 600;
        margin-bottom: $spacing * 2;
    }
}
.resultat {
    color: $body-font-color;
    background-color: #fbfd58;
    font-weight: 600;
    font-style: italic;
}
