.xifres-list {
    @media #{$large-up} {
        display: flex;
    }
    li::before {
        display: none;
    }
}
.dada {
    text-align: center;
    &::before {
        display: none !important;
    }
    .xifres-list & {
        padding-bottom: $spacing * 1.5;
        @media #{$large-up} {
            border-right: 1px solid #fff;
            &:last-child {
                border-right: 0;
            }
        }
    }
    &__num {
        display: block;
        font-size: rem-calc(60);
        line-height: 1;
        .xifres-list & {
            font-size: rem-calc(50);
            font-weight: 800;
        }
        @media #{$medium-up} {
            font-size: rem-calc(70);
        }
        @media #{$large-up} {
            font-size: rem-calc(80);
        }
    }
    &__title {
        display: block;
        font-size: rem-calc(25);
        line-height: 1;
        font-weight: 800;
    }
    &__txt {
        display: block;
        font-weight: bold;
        line-height: 1.4;
        .xifres-list & {
            font-size: rem-calc(16);
            font-weight: 600;
            margin-top: $spacing * 0.5;
        }
    }
}
