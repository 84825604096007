.btn {
    display: inline-block;
    text-decoration: none;
    margin-bottom: $spacing;
    padding: rem-calc(12) $spacing * 1.5 rem-calc(11);
    text-transform: uppercase;
    text-align: center;
    color: #fff;
    background-color: darken(
        $primary-color,
        4%
    ); /*enfosquit perquè passi validació contrast*/
    transition: background-color 0.125s ease-in-out, color 0.125s ease-in-out;
    font-size: rem-calc(14);
    font-weight: bold;
    border: 1px solid $primary-color;
    &:hover,
    &:active {
        text-decoration: none !important;
        color: $primary-color;
        background-color: #fff;
    }
    &.external::after {
        display: none;
    }
    &--border {
        background-color: #fff;
        border-color: #000;
        color: #000;
        &:hover,
        &:active {
            color: #fff;
            background-color: #000;
        }
    }
    &--neg {
        background-color: #fff;
        border-color: #fff;
        color: #000;
        &:hover,
        &:active {
            color: #fff;
            background-color: #000;
        }
    }
    &--inverse {
        background-color: #fff;
        border-color: #fff;
        color: darken($primary-color, 5%);
        &:hover,
        &:active {
            color: #fff;
            background-color: $primary-color;
        }
    }
    &--transp {
        background-color: transparent !important;
        border-color: #fff;
        color: #fff;
        &:hover,
        &:active {
            color: $primary-color;
            background-color: #fff !important;
        }
    }
    &--solid {
        background-color: #000 !important;
        border-color: #000;
        color: #fff;
        &:hover,
        &:active {
            color: #000;
            background-color: #fff !important;
        }
    }
    &--solid-2 {
        background-color: $secondary-color;
        border-color: $secondary-color;
        color: #fff;
        &:hover,
        &:active {
            color: $secondary-color;
            background-color: #fff;
        }
    }
    &--dl,
    &--link {
        background-color: #fff !important;
        border: 1px solid #000;
        color: #000;
        padding-right: $spacing * 3;
        padding: rem-calc(19) $spacing * 3 rem-calc(16) $spacing * 1.5;
        position: relative;
        text-align: left;
        span,
        em {
            font-weight: normal;
            font-style: italic;
            @media #{$large-up} {
                .memories-list &,
                .mem-dest & {
                    display: block;
                }
            }
        }
        &:after {
            content: "";
            display: block;
            @include sprite("ico-descarrega-negre");
            position: absolute;
            right: $spacing;
            top: 50%;
            transform: translateY(-50%);
            transition: top 0.15s;
        }
        &:hover,
        &:active {
            color: #000;
            background-color: #fff !important;
            &:after {
                top: 55%;
            }
        }
        &-neg {
            border-color: #fff;
            color: #fff;
            &:after {
                @include sprite("ico-descarrega");
            }
            &:hover,
            &:active {
                color: #fff;
            }
        }
    }
    &--link {
        &:after {
            @include sprite("ico-link");
            display: block !important;
            opacity: 1;
        }
        &:hover,
        &:active {
            &:after {
                top: 46%;
            }
        }
    }
    &--big {
        background-color: transparent;
        border-color: #000;
        color: #000;
        font-size: rem-calc(16);
        padding: $spacing*.6 $spacing;
        &:hover,
        &:active {
            color: #fff;
            background-color: #000;
        }
    }
    &--disclosure {
        position: relative;
        padding-left: $spacing*3;
        padding-right: $spacing*3;
        width: 100%;
        @media screen and (min-width: 50em) {
            width: auto;
        }
        &::before {
            display: block;
            position: absolute;
            left: $spacing;
            top: 50%;
            transform: translateY(-50%);
            content: '\25B6';
        }
        &.open::before {
            // content: '\25BC';
            content: '\25B2';
        }
    }
    &--report {
        width: 100%;
        time {
            font-weight: 300;
            text-transform: none;
        }
        span {
            display: block;
        }
    }
}
// botons home
.h-btn {
    display: block;
    text-decoration: none;
    font-weight: 800;
    font-size: rem-calc(20);
    text-align: center;
    letter-spacing: -0.8px;
    padding: $spacing * 0.5;
    margin-bottom: $spacing * 0.5;
    transition: background-color 0.15s ease-in-out;
    @media screen and (min-width: 40em) {
        height: $spacing * 4;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: $column-gutter;
    }
    @media #{$medium-up} {
        font-size: rem-calc(26);
        height: $spacing * 6;
    }
    // @media #{$large-up} {
    //     font-size: rem-calc(32);
    //     height: $spacing * 9;
    // }
    &::after {
        display: none !important;
    }
    &--1 {
        color: #fff;
        background-color: #3b5a7a;
        &:hover {
            background-color: darken(#3b5a7a, 10%);
        }
    }
    &--2 {
        color: #fff;
        background-color: $primary-color;
        &:hover {
            background-color: darken($primary-color, 10%);
        }
    }
    &--3 {
        color: $primary-color;
        background-color: #dbecf6;
        &:hover {
            background-color: darken(#dbecf6, 10%);
        }
    }
    &--4,
    &--5,
    &--6 {
        color: #fff;
        font-weight: bold;
        letter-spacing: -0.6px;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: #666;
        position: relative;
        @media screen and (min-width: 40em) {
            margin-bottom: 0;
        }
        @media #{$medium-up} {
            font-size: rem-calc(24);
            height: $spacing * 5;
        }
        &:hover {
            text-decoration: underline;
            &::before {
                background-color: rgba(0,0,0,.7);
            }
        }
        &::before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0,0,0,.5);
            transition: background-color .15s ease-out;
        }
        span {
            position: relative;
            z-index: 1;
        }
    }
    // &--4 {
    //     background-image: url("img/caixa-subscriute-pexels.png");
    // }
    // &--5 {
    //     background-image: url("img/caixa-xxrecerca-pexels.png");
    // }
    // &--6 {
    //     background-image: url("img/caixa-memoria-cientifica-pexels.png");
    // }
}

// pels botons posats amb l'editor

// bàsic
p.btn {
    border: 1px solid $primary-color;
    padding: 0;
    font-weight: bold;
    font-size: rem-calc(14);
    a {
        display: block;
        text-decoration: none;
        color: #fff;
        background-color: darken(
            $primary-color,
            4%
        ); /*enfosquit perquè passi validació contrast*/
        padding: rem-calc(12) $spacing * 1.5 rem-calc(11);
        &:hover {
            color: $primary-color;
            background: #fff;
        }
        &.external::after {
            display: none;
        }
    }
}

// blanc amb border
p.btn-border {
    a {
        @extend .btn;
        background-color: #fff !important;
        border-color: #000;
        color: #000;
        &:hover,
        &:active {
            color: #fff;
            background-color: #000 !important;
        }
    }
}

// negre
p.btn-negre {
    a {
        @extend .btn;
        background-color: #000 !important;
        border-color: #000;
        color: #fff;
        &:hover,
        &:active {
            color: #000;
            background-color: #fff !important;
        }
    }
}

// secundari
p.btn-secundari {
    a {
        @extend .btn;
        background-color: $secondary-color !important;
        border-color: $secondary-color;
        color: #fff;
        &:hover,
        &:active {
            color: $secondary-color;
            background-color: #fff !important;
        }
    }
}

// download
p.btn-dl {
    a {
        background-color: transparent !important;
        border: 1px solid #000;
        color: #000;
        @extend .btn;
        @extend .btn--dl;
    }
}

// enllaç
p.btn-link {
    a {
        background-color: transparent !important;
        border: 1px solid #000;
        color: #000;
        @extend .btn;
        @extend .btn--dl;
        @extend .btn--link;
    }
}
