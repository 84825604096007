.carrecs-list {
    @include undolist;
    font-style: normal;
    font-size: rem-calc(14);
    li {
        border-bottom: 1px solid #d8d8d8;
        margin: 0;
        padding: $spacing * 0.5 0;
        @media screen and (min-width: 40em) {
            position: relative;
            padding-left: 50%;
        }
        &:first-child {
            padding-top: 0;
            strong {
                @media screen and (min-width: 40em) {
                    top: 0;
                }
            }
        }
    }
    strong {
        font-size: 1rem;
        display: block;
        font-weight: 700;
        @media screen and (min-width: 40em) {
            width: 45%;
            border: 1px solid transparent;
            margin-right: $spacing * 0.5;
            position: relative;
            top: 0.5rem;
            position: absolute;
            left: 0;
        }
    }
    a {
        @include hover;
        font-weight: 600;
    }
}
