.collapsible {
  h2 {
    font-size: rem-calc(16);
    @media #{$medium-up} {
      font-size: rem-calc(20);
    }
    @media #{$large-up} {
      font-size: rem-calc(24);
    }
    @media #{$xlarge-up} {
      font-size: rem-calc(26);
    }
    .js & {
      margin: 0;
    }
  }
  h2 button {
    all: inherit;
    border: 0;
    padding: $spacing;
    padding-right: $spacing * 2;
    margin: 0;
    width: 100%;
    background-color: #f2f2f2;
    margin-bottom: $spacing * 0.25;
    position: relative;
    @media #{$medium-up} {
      padding: $spacing * 2;
    }
    cursor: pointer;
    &:hover,
    &:focus {
      text-decoration: underline;
    }
    &[aria-expanded="true"] {
      margin-bottom: 0;
    }
  }
  // button svg {
  //   height: 0.75em;
  //   fill: currentColor;
  // }
  button {
    text-align: left;
    &:after {
      content: "";
      display: block;
      width: $spacing * 0.75;
      height: $spacing * 0.75;
      border: solid $body-font-color;
      border-width: 3px 3px 0 0;
      transform: translateY(-50%) rotate(135deg);
      position: absolute;
      right: $spacing * 0.75;
      top: 50%;
      @media #{$medium-up} {
        right: $spacing;
        border-width: 4px 4px 0 0;
      }
    }
    &[aria-expanded="true"]:after {
      transform: rotate(-45deg);
    }
  }

  h2 + div {
    padding: 0 $spacing $spacing;
    background-color: #f2f2f2;
    margin-bottom: $spacing * 0.25;
    @media #{$medium-up} {
      padding: 0 $spacing * 2 $spacing * 2;
    }
  }
}
.col-ico {
    margin-bottom: 50px;
    h2 {
        position: relative;
        background-repeat: no-repeat;
        background-position: left 30px center;
        font-size: 18px;
        text-transform: uppercase;
        button {
            padding: 2.4rem !important;
            padding-left: 100px !important;

              &:after {
                content: "";
                display: block;
                width: $spacing * 0.75;
                height: $spacing * 0.75;
                border: solid #3185C2;
                border-width: 3px 3px 0 0;
                transform: translateY(-50%) rotate(135deg);
                position: absolute;
                right: $spacing * 0.75;
                top: 50%;
                @media #{$medium-up} {
                  right: $spacing;
                  border-width: 4px 4px 0 0;
                }
              }
              &[aria-expanded="true"]:after {
                transform: rotate(-45deg);
              }
              &:hover, &:focus {
                text-decoration: none;
                background-color: darken(#f2f2f2, 10%);
                //background-color: #3185C2 !important;
                //color: #fff;
                //backdrop-filter: brightness(0) invert(1);
                &:after {
                  //filter: brightness(0) invert(1);
                }
            }
        }

    }
    .content {
      padding-top: 30px;
      .btn-dl {
        a {
          margin-bottom: 0 !important;
        }
      }
    }
}
