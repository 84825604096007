.pagination {
  font-size: 0;
  // margin-top: $spacing * 3;
  li {
    padding: 0;
    margin: 0;
    display: inline-block;
    font-size: rem-calc(18);
    vertical-align: middle;
    &:before {
      display: none;
    }
  }
  a {
    text-decoration: none;
    display: block;
    color: $body-font-color;
    background-color: #f2f2f2;
    width: $spacing * 1.75;
    height: $spacing * 1.75;
    border-radius: 3px;
    text-align: center;
    margin: 0 $spacing * 0.5 $spacing * 0.5;
    line-height: 2.2;
  }
  .current a,
  a:hover,
  a:focus {
    color: #fff;
    background-color: $primary-color;
  }
  .unavailable {
    opacity: 0.5;
    a {
      background-color: transparent;
      &:hover,
      &:focus {
        color: inherit;
        text-decoration: underline;
        background-color: transparent;
      }
    }
  }
  .prev,
  .next,
  .first,
  .last {
    text-align: center;
    a {
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      border-color: transparent;
      border: 2px solid $primary-color;
      &:before {
        content: "";
        display: inline-block;
        // filter: contrast(0);
        font-size: 1rem;
      }
    }
    &:not(.unavailable) a {
      &:hover,
      &:focus {
        background-color: $primary-color;
        &::before {
          filter: invert(100);
        }
      }
    }
  }
  .prev {
    a {
      &:before {
        @include sprite("fleta-paginacio-anterior");
      }
    }
  }
  .next {
    a {
      &:before {
        @include sprite("fleta-paginacio-seguent");
      }
    }
  }
  .first {
    a {
      &:before {
        @include sprite("fleta-paginacio-primera");
      }
    }
  }
  .last {
    a {
      &:before {
        @include sprite("fleta-paginacio-ultima");
      }
    }
  }
}
