.memories-list {
    li {
        display: flex;
        flex-direction: column;
        > * {
            margin: 0;
            + * {
                margin-top: $spacing;
            }
        }
    }
    h2 {
        font-weight: bold;
        font-size: rem-calc(18);
        margin-top: $spacing;
        @media #{$medium-up} {
            font-size: rem-calc(20);
        }
        @media #{$large-up} {
            font-size: rem-calc(22);
        }
    }
    img {
        order: -1;
        margin-right: auto;
        object-fit: cover;
        width: 100%;
    }
}
.mem-dest {
    .row {
        @media #{$medium-up} {
            display: flex;
            align-items: flex-end;
        }
    }
    h2 {
        font-weight: bold;
        margin-bottom: $spacing;
        font-size: rem-calc(22);
        @media #{$medium-up} {
            font-size: rem-calc(24);
        }
        @media #{$large-up} {
            font-size: rem-calc(26);
        }
    }
}
