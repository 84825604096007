// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$ico-espais-humans-name: 'ico-espais-humans';
$ico-espais-humans-x: 0px;
$ico-espais-humans-y: 65px;
$ico-espais-humans-offset-x: 0px;
$ico-espais-humans-offset-y: -65px;
$ico-espais-humans-width: 70px;
$ico-espais-humans-height: 77px;
$ico-espais-humans-total-width: 225px;
$ico-espais-humans-total-height: 195px;
$ico-espais-humans-image: 'img/spritesheet.png?1500302833384';
$ico-espais-humans: (0px, 65px, 0px, -65px, 70px, 77px, 225px, 195px, 'img/spritesheet.png?1500302833384', 'ico-espais-humans', );
$ico-h-mediateca-name: 'ico-h-mediateca';
$ico-h-mediateca-x: 97px;
$ico-h-mediateca-y: 0px;
$ico-h-mediateca-offset-x: -97px;
$ico-h-mediateca-offset-y: 0px;
$ico-h-mediateca-width: 64px;
$ico-h-mediateca-height: 64px;
$ico-h-mediateca-total-width: 225px;
$ico-h-mediateca-total-height: 195px;
$ico-h-mediateca-image: 'img/spritesheet.png?1500302833384';
$ico-h-mediateca: (97px, 0px, -97px, 0px, 64px, 64px, 225px, 195px, 'img/spritesheet.png?1500302833384', 'ico-h-mediateca', );
$ico-h-noticies-name: 'ico-h-noticies';
$ico-h-noticies-x: 97px;
$ico-h-noticies-y: 64px;
$ico-h-noticies-offset-x: -97px;
$ico-h-noticies-offset-y: -64px;
$ico-h-noticies-width: 64px;
$ico-h-noticies-height: 64px;
$ico-h-noticies-total-width: 225px;
$ico-h-noticies-total-height: 195px;
$ico-h-noticies-image: 'img/spritesheet.png?1500302833384';
$ico-h-noticies: (97px, 64px, -97px, -64px, 64px, 64px, 225px, 195px, 'img/spritesheet.png?1500302833384', 'ico-h-noticies', );
$ico-h-publicacions-name: 'ico-h-publicacions';
$ico-h-publicacions-x: 161px;
$ico-h-publicacions-y: 0px;
$ico-h-publicacions-offset-x: -161px;
$ico-h-publicacions-offset-y: 0px;
$ico-h-publicacions-width: 64px;
$ico-h-publicacions-height: 64px;
$ico-h-publicacions-total-width: 225px;
$ico-h-publicacions-total-height: 195px;
$ico-h-publicacions-image: 'img/spritesheet.png?1500302833384';
$ico-h-publicacions: (161px, 0px, -161px, 0px, 64px, 64px, 225px, 195px, 'img/spritesheet.png?1500302833384', 'ico-h-publicacions', );
$ico-observatori-homofobia-name: 'ico-observatori-homofobia';
$ico-observatori-homofobia-x: 161px;
$ico-observatori-homofobia-y: 64px;
$ico-observatori-homofobia-offset-x: -161px;
$ico-observatori-homofobia-offset-y: -64px;
$ico-observatori-homofobia-width: 61px;
$ico-observatori-homofobia-height: 64px;
$ico-observatori-homofobia-total-width: 225px;
$ico-observatori-homofobia-total-height: 195px;
$ico-observatori-homofobia-image: 'img/spritesheet.png?1500302833384';
$ico-observatori-homofobia: (161px, 64px, -161px, -64px, 61px, 64px, 225px, 195px, 'img/spritesheet.png?1500302833384', 'ico-observatori-homofobia', );
$ico-pantallas-amigas-name: 'ico-pantallas-amigas';
$ico-pantallas-amigas-x: 0px;
$ico-pantallas-amigas-y: 0px;
$ico-pantallas-amigas-offset-x: 0px;
$ico-pantallas-amigas-offset-y: 0px;
$ico-pantallas-amigas-width: 97px;
$ico-pantallas-amigas-height: 65px;
$ico-pantallas-amigas-total-width: 225px;
$ico-pantallas-amigas-total-height: 195px;
$ico-pantallas-amigas-image: 'img/spritesheet.png?1500302833384';
$ico-pantallas-amigas: (0px, 0px, 0px, 0px, 97px, 65px, 225px, 195px, 'img/spritesheet.png?1500302833384', 'ico-pantallas-amigas', );
$ico-sindic-greuges-name: 'ico-sindic-greuges';
$ico-sindic-greuges-x: 0px;
$ico-sindic-greuges-y: 142px;
$ico-sindic-greuges-offset-x: 0px;
$ico-sindic-greuges-offset-y: -142px;
$ico-sindic-greuges-width: 53px;
$ico-sindic-greuges-height: 53px;
$ico-sindic-greuges-total-width: 225px;
$ico-sindic-greuges-total-height: 195px;
$ico-sindic-greuges-image: 'img/spritesheet.png?1500302833384';
$ico-sindic-greuges: (0px, 142px, 0px, -142px, 53px, 53px, 225px, 195px, 'img/spritesheet.png?1500302833384', 'ico-sindic-greuges', );
$ico-espais-humans-2x-name: 'ico-espais-humans@2x';
$ico-espais-humans-2x-x: 0px;
$ico-espais-humans-2x-y: 130px;
$ico-espais-humans-2x-offset-x: 0px;
$ico-espais-humans-2x-offset-y: -130px;
$ico-espais-humans-2x-width: 140px;
$ico-espais-humans-2x-height: 154px;
$ico-espais-humans-2x-total-width: 450px;
$ico-espais-humans-2x-total-height: 390px;
$ico-espais-humans-2x-image: 'img/spritesheet@2x.png?1500302833384';
$ico-espais-humans-2x: (0px, 130px, 0px, -130px, 140px, 154px, 450px, 390px, 'img/spritesheet@2x.png?1500302833384', 'ico-espais-humans@2x', );
$ico-h-mediateca-2x-name: 'ico-h-mediateca@2x';
$ico-h-mediateca-2x-x: 194px;
$ico-h-mediateca-2x-y: 0px;
$ico-h-mediateca-2x-offset-x: -194px;
$ico-h-mediateca-2x-offset-y: 0px;
$ico-h-mediateca-2x-width: 128px;
$ico-h-mediateca-2x-height: 128px;
$ico-h-mediateca-2x-total-width: 450px;
$ico-h-mediateca-2x-total-height: 390px;
$ico-h-mediateca-2x-image: 'img/spritesheet@2x.png?1500302833384';
$ico-h-mediateca-2x: (194px, 0px, -194px, 0px, 128px, 128px, 450px, 390px, 'img/spritesheet@2x.png?1500302833384', 'ico-h-mediateca@2x', );
$ico-h-noticies-2x-name: 'ico-h-noticies@2x';
$ico-h-noticies-2x-x: 194px;
$ico-h-noticies-2x-y: 128px;
$ico-h-noticies-2x-offset-x: -194px;
$ico-h-noticies-2x-offset-y: -128px;
$ico-h-noticies-2x-width: 128px;
$ico-h-noticies-2x-height: 128px;
$ico-h-noticies-2x-total-width: 450px;
$ico-h-noticies-2x-total-height: 390px;
$ico-h-noticies-2x-image: 'img/spritesheet@2x.png?1500302833384';
$ico-h-noticies-2x: (194px, 128px, -194px, -128px, 128px, 128px, 450px, 390px, 'img/spritesheet@2x.png?1500302833384', 'ico-h-noticies@2x', );
$ico-h-publicacions-2x-name: 'ico-h-publicacions@2x';
$ico-h-publicacions-2x-x: 322px;
$ico-h-publicacions-2x-y: 0px;
$ico-h-publicacions-2x-offset-x: -322px;
$ico-h-publicacions-2x-offset-y: 0px;
$ico-h-publicacions-2x-width: 128px;
$ico-h-publicacions-2x-height: 128px;
$ico-h-publicacions-2x-total-width: 450px;
$ico-h-publicacions-2x-total-height: 390px;
$ico-h-publicacions-2x-image: 'img/spritesheet@2x.png?1500302833384';
$ico-h-publicacions-2x: (322px, 0px, -322px, 0px, 128px, 128px, 450px, 390px, 'img/spritesheet@2x.png?1500302833384', 'ico-h-publicacions@2x', );
$ico-observatori-homofobia-2x-name: 'ico-observatori-homofobia@2x';
$ico-observatori-homofobia-2x-x: 322px;
$ico-observatori-homofobia-2x-y: 128px;
$ico-observatori-homofobia-2x-offset-x: -322px;
$ico-observatori-homofobia-2x-offset-y: -128px;
$ico-observatori-homofobia-2x-width: 122px;
$ico-observatori-homofobia-2x-height: 128px;
$ico-observatori-homofobia-2x-total-width: 450px;
$ico-observatori-homofobia-2x-total-height: 390px;
$ico-observatori-homofobia-2x-image: 'img/spritesheet@2x.png?1500302833384';
$ico-observatori-homofobia-2x: (322px, 128px, -322px, -128px, 122px, 128px, 450px, 390px, 'img/spritesheet@2x.png?1500302833384', 'ico-observatori-homofobia@2x', );
$ico-pantallas-amigas-2x-name: 'ico-pantallas-amigas@2x';
$ico-pantallas-amigas-2x-x: 0px;
$ico-pantallas-amigas-2x-y: 0px;
$ico-pantallas-amigas-2x-offset-x: 0px;
$ico-pantallas-amigas-2x-offset-y: 0px;
$ico-pantallas-amigas-2x-width: 194px;
$ico-pantallas-amigas-2x-height: 130px;
$ico-pantallas-amigas-2x-total-width: 450px;
$ico-pantallas-amigas-2x-total-height: 390px;
$ico-pantallas-amigas-2x-image: 'img/spritesheet@2x.png?1500302833384';
$ico-pantallas-amigas-2x: (0px, 0px, 0px, 0px, 194px, 130px, 450px, 390px, 'img/spritesheet@2x.png?1500302833384', 'ico-pantallas-amigas@2x', );
$ico-sindic-greuges-2x-name: 'ico-sindic-greuges@2x';
$ico-sindic-greuges-2x-x: 0px;
$ico-sindic-greuges-2x-y: 284px;
$ico-sindic-greuges-2x-offset-x: 0px;
$ico-sindic-greuges-2x-offset-y: -284px;
$ico-sindic-greuges-2x-width: 106px;
$ico-sindic-greuges-2x-height: 106px;
$ico-sindic-greuges-2x-total-width: 450px;
$ico-sindic-greuges-2x-total-height: 390px;
$ico-sindic-greuges-2x-image: 'img/spritesheet@2x.png?1500302833384';
$ico-sindic-greuges-2x: (0px, 284px, 0px, -284px, 106px, 106px, 450px, 390px, 'img/spritesheet@2x.png?1500302833384', 'ico-sindic-greuges@2x', );
$spritesheet-width: 225px;
$spritesheet-height: 195px;
$spritesheet-image: 'img/spritesheet.png?1500302833384';
$spritesheet-sprites: ($ico-espais-humans, $ico-h-mediateca, $ico-h-noticies, $ico-h-publicacions, $ico-observatori-homofobia, $ico-pantallas-amigas, $ico-sindic-greuges, );
$spritesheet: (225px, 195px, 'img/spritesheet.png?1500302833384', $spritesheet-sprites, );
$retina-spritesheet-width: 450px;
$retina-spritesheet-height: 390px;
$retina-spritesheet-image: 'img/spritesheet@2x.png?1500302833384';
$retina-spritesheet-sprites: ($ico-espais-humans-2x, $ico-h-mediateca-2x, $ico-h-noticies-2x, $ico-h-publicacions-2x, $ico-observatori-homofobia-2x, $ico-pantallas-amigas-2x, $ico-sindic-greuges-2x, );
$retina-spritesheet: (450px, 390px, 'img/spritesheet@2x.png?1500302833384', $retina-spritesheet-sprites, );

// These "retina group" variables are mappings for the naming and pairing of normal and retina sprites.
//
// The list formatted variables are intended for mixins like `retina-sprite` and `retina-sprites`.
$ico-espais-humans-group-name: 'ico-espais-humans';
$ico-espais-humans-group: ('ico-espais-humans', $ico-espais-humans, $ico-espais-humans-2x, );
$ico-h-mediateca-group-name: 'ico-h-mediateca';
$ico-h-mediateca-group: ('ico-h-mediateca', $ico-h-mediateca, $ico-h-mediateca-2x, );
$ico-h-noticies-group-name: 'ico-h-noticies';
$ico-h-noticies-group: ('ico-h-noticies', $ico-h-noticies, $ico-h-noticies-2x, );
$ico-h-publicacions-group-name: 'ico-h-publicacions';
$ico-h-publicacions-group: ('ico-h-publicacions', $ico-h-publicacions, $ico-h-publicacions-2x, );
$ico-observatori-homofobia-group-name: 'ico-observatori-homofobia';
$ico-observatori-homofobia-group: ('ico-observatori-homofobia', $ico-observatori-homofobia, $ico-observatori-homofobia-2x, );
$ico-pantallas-amigas-group-name: 'ico-pantallas-amigas';
$ico-pantallas-amigas-group: ('ico-pantallas-amigas', $ico-pantallas-amigas, $ico-pantallas-amigas-2x, );
$ico-sindic-greuges-group-name: 'ico-sindic-greuges';
$ico-sindic-greuges-group: ('ico-sindic-greuges', $ico-sindic-greuges, $ico-sindic-greuges-2x, );
$retina-groups: ($ico-espais-humans-group, $ico-h-mediateca-group, $ico-h-noticies-group, $ico-h-publicacions-group, $ico-observatori-homofobia-group, $ico-pantallas-amigas-group, $ico-sindic-greuges-group, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `retina-sprite` mixin sets up rules and a media query for a sprite/retina sprite.
//   It should be used with a "retina group" variable.
//
// The media query is from CSS Tricks: https://css-tricks.com/snippets/css/retina-display-media-query/
//
// $icon-home-group: ('icon-home', $icon-home, $icon-home-2x, );
//
// .icon-home {
//   @include retina-sprite($icon-home-group);
// }
@mixin sprite-background-size($sprite) {
  $sprite-total-width: nth($sprite, 7);
  $sprite-total-height: nth($sprite, 8);
  background-size: $sprite-total-width $sprite-total-height;
}

@mixin retina-sprite($retina-group) {
  $normal-sprite: nth($retina-group, 2);
  $retina-sprite: nth($retina-group, 3);
  @include sprite($normal-sprite);

  @media (-webkit-min-device-pixel-ratio: 2),
         (min-resolution: 192dpi) {
    @include sprite-image($retina-sprite);
    @include sprite-background-size($normal-sprite);
  }
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}

// The `retina-sprites` mixin generates a CSS rule and media query for retina groups
//   This yields the same output as CSS retina template but can be overridden in SCSS
//
// @include retina-sprites($retina-groups);
@mixin retina-sprites($retina-groups) {
  @each $retina-group in $retina-groups {
    $sprite-name: nth($retina-group, 1);
    .#{$sprite-name} {
      @include retina-sprite($retina-group);
    }
  }
}
