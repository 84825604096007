.recerca-projectes {
    .logProj {
        list-style: none;
        margin-bottom: 1rem;
        li {
            display: inline-block;
            padding-right: 1.5rem;
            padding-left: 0;
            &:before {
                content: none;
            }
        }
    }
    .contingutDesplegable {
        display: none;
        border: 2px solid rgb(151, 151, 151);
        border-top:0px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        margin-bottom: 20px;
        padding: 20px;
        .contingut {
            background-color: rgb(249, 249, 249);
            border-radius: 4px;
            padding: 20px 30px;
            dl {
                display: flex;
                flex-flow: row wrap;
                border-width: 1px 1px 0 0;
                font-size: 14px;
                margin-bottom: 5px;
              }
              dt {
                flex-basis: 45%;
                padding: 10px 0px;
                font-weight: bold;
                margin-right: 0;
                border-bottom: 1px solid rgb(214, 214, 214);
              }
              dd {
                flex-basis: 45%;
                flex-grow: 1;
                margin: 0;
                padding: 10px 0px;
                border-bottom: 1px solid rgb(214, 214, 214);
              }
              p {
                font-size: 14px;
              }
              h3 {
                margin-bottom: .8rem;
                font-size: 16px;
              }
        }
      }
    .head-despl {
        padding: 20px;
        padding-left: 30px;
        padding-right: 50px;
        border: 2px solid rgb(151, 151, 151);
        border-radius: 8px;
        margin-bottom: 20px;
        dl {
            font-size: 12px;
            @media #{$medium-up} {
                margin-bottom: 0;
                border-left: 1px solid rgb(214, 214, 214);
                padding-left: 20px;
            }

            dt {
                font-weight: bold;
            }
        }
        h3 {
            @media #{$medium-up} {
                margin-bottom: 0;
            }
        }
    }
    .open-despl {
        border-bottom: 0px;
        margin-bottom: 0;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        svg {
            transform: rotate(180deg);
        }
    }
    .houdiniForm {
        input[type=checkbox] {
            position: absolute;
            right: -5px;
            top: 15px;
            &:checked {
                background-color: rgb(45, 122, 178);
                border: 1px solid rgb(45, 122, 178);
                &:after {
                    border: 2px solid #fff;
                    border-right: none;
                    border-top: none;
                    -webkit-transform: rotate(-45deg);
                    -ms-transform: rotate(-45deg);
                    transform: rotate(-45deg);
                }
            }
        }

        label {
            text-transform: none;
            letter-spacing: 0;
            padding: 20px 0px;
            border-bottom: 1px solid rgb(151, 151, 151);
            position: relative;
            margin-bottom: 0;

        }
        &:first-child {
            border-top: 1px solid rgb(151, 151, 151);
        }
        .button {
            margin-top: 20px;
        }
    }
}
