.block-list {
    @include undolist;
    @include block-grid(1);
    margin-left: 0 !important;
    margin-right: 0 !important;
    // margin-bottom: $spacing !important;
    margin-bottom: 0 !important;
    &--2 {
        @media screen and (min-width: 35em) {
            @include block-grid(2);
        }
    }
    &--3 {
        @media screen and (min-width: 30em) {
            @include block-grid(2);
        }
        @media screen and (min-width: 40em) {
            @include block-grid(3);
        }
    }
    &--4 {
        @media screen and (min-width: 35em) {
            @include block-grid(2);
        }
        @media #{$medium-up} {
            @include block-grid(3);
        }
        @media #{$large-up} {
            @include block-grid(4);
        }
    }
    &--5 {
        @media screen and (min-width: 35em) {
            @include block-grid(2);
        }
        @media screen and (min-width: 50em) {
            @include block-grid(3);
        }
        @media #{$medium-up} {
            @include block-grid(4);
        }
        @media #{$large-up} {
            @include block-grid(5, $spacing: 3rem);
            // @include block-grid(5);
        }
    }
    &--6 {
        @media screen and (min-width: 35em) {
            @include block-grid(2);
        }
        @media screen and (min-width: 50em) {
            @include block-grid(3);
        }
        @media #{$medium-up} {
            @include block-grid(4);
        }
        @media #{$large-up} {
            @include block-grid(6);
        }
    }
    &--7 {
        margin-left: 0 !important;
        margin-right: 0 !important;
        @media screen and (min-width: 25em) {
            @include block-grid(2);
        }
        @media screen and (min-width: 40em) {
            @include block-grid(3);
        }
        @media screen and (min-width: 48em) {
            @include block-grid(4);
        }
        @media screen and (min-width: 60em) {
            @include block-grid(5);
        }
        @media #{$large-up} {
            @include block-grid(7);
        }
    }
    &--8 {
        margin-left: 0 !important;
        margin-right: 0 !important;
        @media screen and (min-width: 25em) {
            @include block-grid(2);
        }
        @media screen and (min-width: 40em) {
            @include block-grid(3);
        }
        @media screen and (min-width: 48em) {
            @include block-grid(4);
        }
        @media screen and (min-width: 60em) {
            @include block-grid(5);
        }
        @media #{$large-up} {
            @include block-grid(8);
        }
    }
    &--simple {
        margin-left: -$spacing * 0.5 !important;
        margin-right: -$spacing * 0.5 !important;
        li {
            &::before {
                display: none !important;
            }
        }
    }
    &--logos {
        @include block-grid(2);
        margin-left: -$spacing !important;
        margin-right: -$spacing !important;
        display: flex !important;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        @media screen and (min-width: 35em) {
            @include block-grid(3);
        }
        @media #{$medium-up} {
            @include block-grid(4);
        }
        li {
            margin: 0;
            text-align: center;
            padding-left: $spacing;
            padding-right: $spacing;
            &::before {
                display: none;
            }
        }
        img {
            margin-top: $spacing * 0.5;
            margin-bottom: $spacing * 0.5;
            // margin: $spacing*.5;
            // width: 100%;
        }
    }
    li {
        margin-bottom: 0;
        &:before {
            display: block;
        }
    }
    // a {
    //     &:after {
    //         content: "";
    //         display: block;
    //         position: absolute;
    //         left: 0;
    //         top: 0;
    //         bottom: 0;
    //         width: 100%;
    //     }
    // }
    &__wrapper {
        position: relative;
        > *:last-child {
            margin-bottom: 0;
        }
    }
}

// Estils per l'editor
.block-list-2 {
    @extend .block-list;
    @extend .block-list--2;
}
.block-list-3 {
    @extend .block-list;
    @extend .block-list--3;
}
.block-list-logos {
    // @extend .block-list--4;
    @extend .block-list--logos;
}
