.destacat {
  padding: $spacing 0;
  color: #fff;
  background-color: #313131;
  margin: $spacing 0;
  @media #{$medium-up} {
    padding: $spacing * 1.5 0;
  }
  @media #{$large-up} {
    padding: $spacing * 2 0;
  }
  &--bg {
    background-repeat: no-repeat;
    // background-image: url("https://picsum.photos/1400/300");
    background-size: cover;
    background-position: top center;
    position: relative;
    &:before {
      content: "";
      display: block;
      background-color: rgba(#000, 0.6);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
  h2 {
    color: inherit;
    font-size: rem-calc(22);
    font-weight: 800;
    letter-spacing: 0.65px;
    margin-bottom: $spacing * 1.5;
    @media #{$medium-up} {
      font-size: rem-calc(24);
      margin-bottom: $spacing * 3;
    }
    @media #{$large-up} {
      font-size: rem-calc(26);
    }

    // llistat notícies
    .noticies & {
      font-size: rem-calc(30);
      margin-bottom: $spacing * 1.5;
      @media #{$medium-up} {
        font-size: rem-calc(40);
      }
      @media #{$large-up} {
        font-size: rem-calc(44);
      }
      @media #{$xlarge-up} {
        font-size: rem-calc(46);
      }
      @media screen and (min-height: 60em) {
        margin-top: $spacing * 3;
      }
    }
  }
  .time {
    display: block;
    font-weight: bold;
    font-size: rem-calc(18);
    &:after {
      content: "";
      display: block;
      width: $spacing * 6;
      height: 2px;
      background-color: #fff;
      margin-top: $spacing * 0.5;
      margin-bottom: $spacing;
    }
  }
  h3 {
    font-size: rem-calc(30);
    font-weight: bold;
    @media #{$medium-up} {
      font-size: rem-calc(42);
    }
    @media #{$large-up} {
      font-size: rem-calc(46);
    }
  }
  p {
    font-size: 1rem;
    font-weight: 600;
    @media #{$medium-up} {
      font-size: rem-calc(18);
      margin-bottom: 0;
    }
  }
  a {
    @include hover;
    color: #fff;
  }
  .nom {
    font-size: rem-calc(22);
    margin-bottom: $spacing * 0.25;
    font-weight: bold;
    line-height: 1.3;
    @media #{$medium-up} {
      font-size: rem-calc(25);
    }
    @media #{$large-up} {
      font-size: rem-calc(27);
    }
  }
  .role {
    font-style: italic;
    font-size: 1rem;
    font-weight: normal;
    @media #{$medium-up} {
      font-size: rem-calc(18);
    }
    &:after {
      content: "";
      display: block;
      width: $spacing * 6;
      height: 2px;
      background-color: #fff;
      margin-top: $spacing * 1.5;
      margin-bottom: $spacing * 0.5;
      @media #{$medium-up} {
        margin-top: $spacing * 2;
      }
    }
  }
  img {
    display: block;
    margin-bottom: $spacing;
    border-radius: 50%;
    width: rem-calc(300);
    // height: rem-calc(300);
  }
  &--entrevista {
    @media #{$large-up} {
      margin-bottom: $spacing * 2;
    }
    @media #{$large-up} {
      margin-bottom: $spacing * 3;
    }
    img {
      margin-top: $spacing;
      @media #{$medium-up} {
        margin-top: 0;
      }
    }
  }
  &--testimonis {
    color: #fff;
    background-color: $primary-color;
    padding: $spacing;
    margin-top: 0;
    @media #{$medium-up} {
      padding: $spacing * 1.5;
    }
    @media #{$large-up} {
      padding: $spacing * 1.5 $spacing;
    }
    h2 {
      font-weight: 300;
      font-size: rem-calc(30);
      margin-bottom: $spacing;
      @media #{$medium-up} {
        font-size: rem-calc(34);
      }
      @media #{$large-up} {
        font-size: rem-calc(38);
      }
    }
    h3 {
      color: #fff;
      font-size: rem-calc(28);
      @media #{$medium-up} {
        font-size: rem-calc(30);
      }
      @media #{$large-up} {
        font-size: rem-calc(33);
      }
    }
    .role {
      margin-bottom: $spacing;
      &:after {
        display: none;
      }
    }
  }
  @media #{$medium-up} {
    .row .row {
      display: flex;
      > div:last-child {
        display: flex;
        flex-direction: column;
        .btn {
          margin-top: auto;
          margin-bottom: 0;
          width: 100%;
        }
      }
    }
  }
}
.societat .h-actualitat {
  h2 {
    color: $primary-color;
    font-weight: 300;
    font-size: rem-calc(30);
    margin-bottom: $spacing;
    @media #{$medium-up} {
      font-size: rem-calc(34);
    }
    @media #{$large-up} {
      font-size: rem-calc(38);
    }
    strong {
      display: block;
    }
  }
  .actualitat__item {
    border-bottom: 1px solid #979797;
    padding-bottom: $spacing;
  }
}
