.sub-nav {
  background-color: #313131;
  padding: $spacing 0;
  @media #{$large-up} {
    padding-bottom: 0;
  }
  ul {
    font-size: rem-calc(14);
    font-weight: 600;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0;
    @media #{$large-up} {
      font-size: rem-calc(16);
      /*flexbox fallback*/
      .ie9 & {
        text-align: center;
        > * {
          display: inline-block;
        }
      }
      /*/flexbox fallback*/
    }
  }
  li {
    padding: $spacing * 0.25 $spacing * 0.5;
    margin-bottom: 0;
    @media #{$large-up} {
      padding-top: 0;
      padding-bottom: 0;
    }
    @media screen and (min-width: 80em) {
      padding-left: $spacing * 0.75;
      padding-right: $spacing * 0.75;
    }
    &:before {
      display: none;
    }
  }
  a {
    color: #fff;
    text-decoration: none;
    @media #{$large-up} {
      position: relative;
      display: block;
      padding-bottom: $spacing * 1.75;
    }
    &:hover,
    &:focus {
      text-decoration: underline;
      @media #{$large-up} {
        text-decoration: none;
        &:after {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          bottom: 0;
          background-color: $primary-color;
          height: $spacing;
          width: 100%;
        }
      }
    }
  }
}
