.section {
    &--bg {
        .btn--inverse {
            background-color: #fff;
            &:hover,
            &:focus {
                color: #fff;
                background-color: $primary-color;
            }
        }
        .btn--border {
            background-color: #fff;
            &:hover,
            &:focus {
                color: #fff;
                background-color: #000;
            }
        }
    }
    &--img {
        color: #fff;
        background: #444 url("img/fons-xifres.jpg") no-repeat;
        background-size: cover;
        position: relative;
        @media #{$retina} {
            background-image: url("img/fons-xifres@2x.jpg");
        }
        &:before {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.5);
        }
        h2 {
            color: #fff;
            @media #{$medium-up} {
                display: flex;
                white-space: nowrap;
            }
        }
    }
    &--xifres {
        padding-top: 0;
        // &::before {
        // display: none;
        // }
        @media #{$large-up} {
            margin-top: -$spacing * 2;
            position: relative;
            z-index: 1;
        }
        .intro-txt {
            padding: 1.5rem $spacing * 1.5;
            margin-bottom: $spacing * 2;
            background-color: $primary-color;
            position: relative;
            @media #{$medium-up} {
                display: flex;
                align-items: center;
            }
            h2 {
                font-weight: 800;
                @media #{$medium-up} {
                    font-size: rem-calc(26);
                    margin-bottom: 0;
                }
            }
            p {
                font-weight: 300;
                margin-bottom: 0;
                @media #{$medium-up} {
                    border-left: 1px solid #fff;
                    padding-left: $spacing * 1.5;
                    margin-left: $spacing * 1.5;
                    font-size: rem-calc(26);
                }
            }
        }
    }
}
