.avatar {
    display: flex;
    align-items: center;
    font-weight: normal;
    position: relative;
    text-align: left;
    &:hover {
        img {
            opacity: 0.9;
        }
    }
    img {
        margin: 0 $spacing * 0.5 0 0;
        opacity: 1;
        transition: opacity 0.1s ease-in-out;
        border-radius: 50%;
        width: 51px;
        height: 51px;
    }
    a {
        text-decoration: none;
        color: #000;
        &:hover {
            text-decoration: underline;
        }
        &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 100%;
        }
        &.external::after {
            display: none;
        }
    }
    p {
        margin-bottom: 0;
        &.nom {
            font-size: rem-calc(16);
            font-weight: bold;
            color: #000;
        }
        &.carrec {
            font-size: rem-calc(14);
            color: #666;
            letter-spacing: -0.2px;
        }
    }
    span {
        .noms-list & {
            display: block;
        }
    }
}
