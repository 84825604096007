@media screen and (min-width: 40em) {
    .media {
        display: flex;
        align-items: flex-start;
        &--reverse {
            flex-direction: row-reverse;
            .media__content {
                padding-left: 0;
                padding-right: $spacing;
            }
        }
    }
    .media__content {
        flex: 1;
        padding-left: $spacing;
    }
    .media__img img {
        max-width: $spacing * 11;
    }
}
