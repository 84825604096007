.sub-nav-2 {
  li {
    &.current {
      a {
        @media #{$medium-up} {
          &:before {
            @include triangle($spacing * 0.5, $primary-color, top);
          }
        }
      }
    }
  }
}
