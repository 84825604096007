// https://inclusive-components.design/tabbed-interfaces/

[role="tablist"] {
    margin: 0;
    text-align: center;
    padding: $spacing 0;
    @media #{$medium-up} {
        padding: $spacing * 2 0;
    }
    li {
        display: inline-block;
        list-style: none;
        font-size: rem-calc(14);
        padding: 0;
        margin: 0;
        @media #{$large-up} {
            font-size: rem-calc(16);
            position: relative;
        }
    }
    a {
        display: inline-block;
        text-decoration: none;
        background: #fff;
        color: #000;
        font-weight: 600;
        margin: 0 $spacing * 0.5;
        padding: $spacing * 0.25 0;
        border-bottom: 1px solid transparent;
        &:focus:not(:focus-visible) {
            outline: 0;
        }
    }
}

[role="tablist"] a:hover,
[role="tablist"] [aria-selected] {
    border-bottom: 1px solid;
    @media screen and (min-width: 80em) {
        border-bottom: 0;
        position: relative;
        &:after {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            top: $spacing * 1.5;
            background-color: $secondary-color;
            height: $spacing * 4;
            z-index: 1;
        }
    }
}

@media screen and (max-width: 30em) {
    [role="tablist"] li,
    [role="tablist"] a {
        display: block;
        position: static;
    }

    [role="tablist"] a {
        border-bottom: 0;
    }

    [role="tablist"] li + li a {
        border-top: 0 !important;
    }

    [role="tablist"] [aria-selected] {
        font-weight: 800;
    }
    [role="tablist"] [aria-selected],
    [role="tablist"] a:hover {
        // position: static;
        border: 0;
        // text-decoration: underline;
    }

    // [role="tablist"] [aria-selected]::after {
    //   content: "\0020⬅";
    // }
}

// .tabs {
//   &__panels {
//     .js & {
//       position: relative;
//     }
//   }
// }
.panel {
    .no-js & {
        display: none;
    }
    .js & {
        position: absolute;
        opacity: 0;
        transition: opacity 0.15s ease-in-out;
    }
    &.is-current {
        .no-js & {
            display: block;
        }
        .js & {
            opacity: 1;
        }
    }
    &-top {
        position: relative;
        background-color: #ccc;
        &:before {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(#000, 0.5);
        }
        h1 {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            margin: 0;
            padding: 0;
            width: 100%;
            font-weight: bold;
            color: #fff;
            font-size: rem-calc(30);
            text-align: center;
            padding: 0 $column-gutter * 0.5;
            // margin-top: $spacing;
            @media #{$medium-up} {
                font-size: rem-calc(40);
                // margin-top: $spacing * 2;
            }
            @media #{$large-up} {
                font-size: rem-calc(50);
                // margin-top: $spacing * 4;
            }
        }
    }
}

// tabs per subportada recerca
.tabbed--style1 {
    .tab-menu {
        @include undolist;
        padding-top: 0;
        text-align: left;
        letter-spacing: -0.4px;
        text-transform: uppercase;
        a {
            font-weight: bold;
            color: $primary-color;
            border-bottom: 4px solid rgba($box-1, 0.4);
            margin: 0;
            transition: border-bottom-width 0.1s ease-in;
            @media screen and (max-width: 30em) {
                margin-bottom: 0.5rem;
            }
            @media screen and (min-width: 30.0625em) {
                margin-right: 1.5rem;
                &:hover {
                    border-bottom-width: 8px;
                }
            }
            &[aria-selected="true"] {
                border-bottom: 8px solid $box-1;
            }
            &::after {
                display: none;
            }
        }
    }
}
