.table-extended {
    border-collapse: separate;
    thead {
        th {
            font-size: rem-calc(14);
            text-transform: uppercase;
            font-weight: 600;
            letter-spacing: 0.78px;
            color: $body-font-color;
            // white-space: nowrap;
            padding-left: $spacing * 0.5;
            padding-right: $spacing * 0.5;
            // &:first-child {
            //     min-width: 20rem;
            // }
            // &:nth-child(5),
            // &:nth-child(6),
            // &:nth-child(7) {
            //     min-width: 8rem;
            // }
        }
    }
    tbody {
        tr {
            // position: relative;
            th,
            td {
                background-color: #f1f1f1;
                font-size: rem-calc(14);
                font-weight: 600;
                border-right: 3px solid #fff;
                border-bottom: 10px solid #fff;
                vertical-align: top;
                @media #{$medium-up} {
                    padding: $spacing;
                }
                &:last-child {
                    border-right: 0;
                }
            }
            > *:nth-child(even) {
                background-color: #e8e8e8;
            }
            &:last-child {
                th,
                td {
                    border-bottom: 0;
                }
            }
            &:hover {
                th,
                td {
                    background-color: darken(#e8e8e8, 2%);
                }
            }
        }
    }
    a {
        // @include hover;
        color: $body-font-color;
        // &::after {
        //     content: "";
        //     display: block;
        //     position: absolute;
        //     left: 0;
        //     right: 0;
        //     bottom: 0;
        //     top: 0;
        // }
    }
    // time {
    //     white-space: nowrap;
    // }
}
.status {
    &:before {
        content: "";
        display: block;
        width: $spacing;
        height: $spacing;
        border-radius: 50%;
    }
    &--1:before {
        background-color: #7ed321;
    }
    &--2:before {
        background-color: #f5a623;
    }
    &--3:before {
        background-color: #d0021b;
    }
}
