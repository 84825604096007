.is-hidden {
  @include visually-hidden;
}
.is-visible {
  display: block;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.rounded {
  border-radius: $global-rounded;
  border-radius: 50%;
}
// Miscellaneous useful HTML classes
.left {
  float: left !important;
}
.right {
  float: right !important;
}
.clearfix {
  @include clearfix;
}

// Hide visually and from screen readers
.hide {
  display: none;
}

// Hide visually and from screen readers, but maintain layout
.invisible {
  visibility: hidden;
}

.is-visible {
  display: block !important;
}

/*https://www.paciellogroup.com/blog/2012/08/notes-on-accessible-css-image-sprites/*/
.vh {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

// screen reader only
.sr {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

// ample de punta a punta
.u-wider {
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  > div {
    max-width: $row-width;
    width: 100%;
    margin: 0 auto;
  }
}

// imatge ample de punta a punta
.u-full {
  width: 100%;
}

// utility classes (margins & paddings)
// padding-top
.pt0 {
  padding-top: 0;
}
.pt-smallest {
  padding-top: $spacing * 0.25;
}
.pt-smaller {
  padding-top: $spacing * 0.5;
}
.pt-small {
  padding-top: $spacing * 0.75;
}
.pt-normal {
  padding-top: $spacing;
}
.pt-big {
  padding-top: $spacing * 1.25;
}
.pt-bigger {
  padding-top: $spacing * 1.5;
}
.pt-biggest {
  padding-top: $spacing * 1.75;
}
// padding-bottom
.pb0 {
  padding-bottom: 0;
}
.pb-smallest {
  padding-bottom: $spacing * 0.25;
}
.pb-smaller {
  padding-bottom: $spacing * 0.5;
}
.pb-small {
  padding-bottom: $spacing * 0.75;
}
.pb-normal {
  padding-bottom: $spacing;
}
.pb-big {
  padding-bottom: $spacing * 1.25;
}
.pb-bigger {
  padding-bottom: $spacing * 1.5;
}
.pb-biggest {
  padding-bottom: $spacing * 1.75;
}
// margin-top
.mt0 {
  margin-top: 0;
}
.mt-smallest {
  margin-top: $spacing * 0.25;
}
.mt-smaller {
  margin-top: $spacing * 0.5;
}
.mt-small {
  margin-top: $spacing * 0.75;
}
.mt-normal {
  margin-top: $spacing;
}
.mt-big {
  margin-top: $spacing * 1.25;
}
.mt-bigger {
  margin-top: $spacing * 1.5;
}
.mt-biggest {
  margin-top: $spacing * 1.75;
}
// margin-bottom
.mb0 {
  margin-bottom: 0;
}
.mb-smallest {
  margin-bottom: $spacing * 0.25;
}
.mb-smaller {
  margin-bottom: $spacing * 0.5;
}
.mb-small {
  margin-bottom: $spacing * 0.75;
}
.mb-normal {
  margin-bottom: $spacing;
}
.mb-big {
  margin-bottom: $spacing * 1.25;
}
.mb-bigger {
  margin-bottom: $spacing * 1.5;
}
.mb-biggest {
  margin-bottom: $spacing * 1.75;
}

.mfp-close, .mfp-title, .mfp-counter{
    color:#000 !important;
}
