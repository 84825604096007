.sub-nav-2-container {
    background-color: rgba(#000, 0.6);
    padding: $spacing * 0.5 0;
    @media #{$medium-up} {
        padding-top: $spacing;
        padding-bottom: $spacing;
    }
}
.sub-nav-2 {
    font-size: 0;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
    margin-left: -$spacing * 0.5;
    margin-right: -$spacing * 0.5;
    li {
        margin: 0;
        padding: 0;
        display: inline-block;
        font-size: rem-calc(12);
        font-weight: bold;
        letter-spacing: 0.78px;
        @media #{$medium-up} {
            font-size: rem-calc(14);
        }
        &:before {
            display: none;
        }
        &.active {
            a {
                border-bottom: 2px solid #fff;
                @media screen and (min-width: 80em) {
                    text-decoration: none;
                    position: relative;
                    border-bottom: none;
                    &:before {
                        content: "";
                        display: block;
                        @include triangle(
                            $spacing * 0.5,
                            darken($primary-color, 4%),
                            top
                        );
                        position: absolute;
                        top: -$spacing;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }
            }
        }
    }
    a {
        color: #fff;
        display: block;
        padding: $spacing * 0.125 0;
        margin: 0 0.5rem;
        text-decoration: none;
        border-bottom: 2px solid transparent;
        transition: border-bottom-color 0.125s ease-out;
        &:hover {
            text-decoration: none;
            border-bottom: 2px solid rgba(#fff, 0.5);
        }
        &.external::after {
            display: none;
        }
    }
}
