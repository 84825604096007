@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: local("Open Sans Light"), local("OpenSans-Light"), url(../../fonts/Open-Sans-Light.ttf) format("truetype"),
    url(../../fonts/Open-Sans-Light.woff) format("woff"), url(../../fonts/Open-Sans-Light.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url(../../fonts/Open-Sans.eot);
  src: local("Open Sans"), local("Open Sans Regular"), local("OpenSans-Regular"),
    url(../../fonts/Open-Sans.eot?#iefix) format("embedded-opentype"), url(../../fonts/Open-Sans.ttf) format("truetype"),
    url(../../fonts/Open-Sans.woff) format("woff"), url(../../fonts/Open-Sans.woff2) format("woff2"),
    url(../../fonts/Open-Sans.svg#OpenSans) format("svg");
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"),
    url(../../fonts/Open-Sans-SemiBold.ttf) format("truetype"), url(../../fonts/Open-Sans-SemiBold.woff) format("woff"),
    url(../../fonts/Open-Sans-SemiBold.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: local("Open Sans Bold"), local("OpenSans-Bold"), url(../../fonts/Open-Sans-Bold.ttf) format("truetype"),
    url(../../fonts/Open-Sans-Bold.woff) format("woff"), url(../../fonts/Open-Sans-Bold.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  src: local("Open Sans ExtraBold"), local("OpenSans-ExtraBold"),
    url(../../fonts/Open-Sans-ExtraBold.ttf) format("truetype"),
    url(../../fonts/Open-Sans-ExtraBold.woff) format("woff"), url(../../fonts/Open-Sans-ExtraBold.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  src: local("Open Sans Italic"), local("OpenSans-Italic"), url(../../fonts/Open-Sans-Italic.ttf) format("truetype"),
    url(../../fonts/Open-Sans-Italic.woff) format("woff"), url(../../fonts/Open-Sans-Italic.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 600;
  src: local("Open Sans SemiBold Italic"), local("OpenSans-SemiBoldItalic"),
    url(../../fonts/Open-Sans-SemiBold-Italic.ttf) format("truetype"),
    url(../../fonts/Open-Sans-SemiBold-Italic.woff) format("woff"),
    url(../../fonts/Open-Sans-SemiBold-Italic.woff2) format("woff2");
  font-display: swap;
}
