.list.cercador {
  > * + * {
    margin-top: $spacing;
  }
  article {
    border-bottom: 1px solid #dddddd;
    padding-bottom: $spacing;
    > * {
      margin: 0;
      + * {
        margin-top: $spacing*.5;
      }
    }
  }
  .breadcrumbs {
    font-size: rem-calc(14);
    @include undolist;
    li {
      display: inline;
      &::after {
        content: '>';
        margin-left: $spacing*.25;
        margin-right: $spacing*.25;
      }
      &:last-child::after {
        display: none;
      }
    }
  }
  h2 {
    font-size: rem-calc(18);
    @media #{$medium-up} {
      font-size: rem-calc(20);
    }
    @media #{$large-up} {
      font-size: rem-calc(22);
    }
  }
  a {
    @include hover;
  }
  p {
    font-size: 1rem;
  }
}
