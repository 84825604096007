.galeria {
  margin-top: $spacing * 2;
  .slick-slide {
    margin: 0 0.5px;
    width: 100%;
    max-width: rem-calc(575);
  }
  .caption {
    // padding: 0 $column-gutter * 0.5;
    padding: 0 $spacing * 3;
    position: relative;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    text-align: center;
    // @media screen and (min-width: 36em) {
    //   padding: 0 $spacing * 3;
    // }
    @media #{$medium-up} {
      padding: 0 $column-gutter * 0.5;
    }
    @media #{$large-up} {
      padding: 0;
    }
    p {
      font-weight: 300;
      font-size: rem-calc(14);
      margin-bottom: 0;
      line-height: 1.4;
      @media #{$medium-up} {
        font-size: rem-calc(16);
      }
    }
    strong {
      font-weight: 600;
    }
  }
  .slick-active {
    .caption {
      opacity: 1;
    }
  }
  .slick-arrow {
    z-index: 1;
    height: 32px;
    width: 32px;
    // @media screen and (min-width: 36em) {
      top: auto;
      bottom: 0;
      transform: none;
    // }
    &:before {
      content: "";
      font-size: 1rem;
    }
    &:focus {
      outline: 1px dotted #fff;
      box-shadow: none;
    }
  }
  .slick-prev {
    // left: $column-gutter * 0.5;
    left: 0;
    // @media screen and (min-width: 36em) {
    //   left: 15%;
    // }
    // @media #{$large-up} {
    //   left: 20%;
    // }
    // @media #{$xlarge-up} {
    //   left: 23%;
    // }
    &:before {
      @include sprite("fletxa-galeria-anterior");
    }
  }
  .slick-next {
    // right: $column-gutter * 0.5;
    right: 0;
    // @media screen and (min-width: 36em) {
    //   right: 15%;
    // }
    // @media #{$large-up} {
    //   right: 20%;
    // }
    // @media #{$xlarge-up} {
    //   right: 23%;
    // }
    &:before {
      @include sprite("fletxa-galeria-seguent");
    }
  }
}
