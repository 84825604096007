.membres-list {
  @include block-grid($per-row: 1);
  @media screen and (min-width: 28em) {
    @include block-grid(2);
  }
  @media screen and (min-width: 40em) {
    @include block-grid(3);
  }
  @media #{$large-up} {
    @include block-grid(4, $spacing: $block-grid-default-spacing * 2);
  }
  li {
    margin-bottom: $spacing * 2;
    @media #{$large-up} {
      margin-bottom: $spacing * 3;
    }
    &:before {
      display: none;
    }
  }
}

.membre {
  text-align: center;
  display: flex;
  flex-direction: column;
  @media (hover: hover) {
    &:hover,
    &:focus {
      .btn {
        background-color: #313131;
        border-color: #313133;
      }
      .membre__foto {
        span,
        &:before {
          opacity: 0.5;
          @supports (mix-blend-mode: color) {
            opacity: 1;
          }
        }
        &:before {
          transform: translate(-50%, -50%) rotate(0);
        }
      }
    }
  }
  a {
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      bottom: 0;
      z-index: 1;
    }
  }
  &__nom {
    margin: 0 0 $spacing;
    font-size: rem-calc(18);
    @media #{$large-up} {
      font-size: rem-calc(20);
    }
    a {
      text-decoration: none;
      color: $primary-color;
    }
  }
  p {
    order: -1;
    margin-bottom: $spacing * 0.5;
    font-size: rem-calc(14);
    color: #666666;
    font-style: italic;
    line-height: 1.2;
    @media #{$medium-up} {
      font-size: rem-calc(16);
    }
  }
  &__foto {
    order: -2;
    margin-bottom: $spacing * 0.5;
    position: relative;
    &:before {
      content: "+";
      font-size: rem-calc(64);
      font-weight: 300;
      color: #fff;
      position: absolute;
      z-index: 1;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(-180deg);
      opacity: 0;
      transition: opacity 0.125s ease-in-out, transform 0.125s ease-in-out;
    }

    span {
      display: block;
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
      bottom: 0;
      background-color: $primary-color;
      opacity: 0;
      mix-blend-mode: color;
      transition: opacity 0.125s ease-in-out;
    }

    img {
      margin-bottom: 0;
      @media screen and (min-width: 28em) {
        width: 100%;
      }
    }
  }
  .btn {
    padding-left: $spacing;
    padding-right: $spacing;
    display: block;
    margin: auto auto 0;
    @media #{$large-up} {
      display: inline-block;
      margin: auto 0 0;
    }
  }
}
