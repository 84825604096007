.share-list {
  @include undolist;
  font-weight: 600;
  li {
    padding-left: $spacing * 1.5;
    &:before {
      content: "";
      background-color: transparent;
      top: 0.3em;
      font-size: 1rem;
    }
    &.fb:before {
      @include sprite("ico-facebook-compartir");
    }
    &.tw:before {
      @include sprite("ico-twitter-noticies");
    }
    &.email:before {
      @include sprite("ico-email-compartir");
    }
    &.linkedin:before {
        @include sprite("ico-linkedin-noticies");
      }
  }
  a {
    @include hover;
    &.external::after {
      display: none;
    }
  }
}
