.lang-switcher {
  margin: 0 $spacing $spacing $spacing * 1.5;
  position: relative;
  float: left;
  @media screen and (min-width: 50em) {
    margin: 0 0 0 $spacing;
    .ie9 & {
      float: right;
    }
  }
}
.lang-button {
  padding: 0;
  border: none;
  font: inherit;
  color: #000;
  background-color: transparent;
  cursor: pointer;
  letter-spacing: -0.4px;
  .no-js & {
    display: none;
  }
  &:after {
    content: "";
    @include sprite("kland-desplega-idioma");
    margin-left: $spacing * 0.25;
  }
  &:hover {
    text-decoration: underline;
  }
  &.open:after {
    transform: rotate(180deg);
    position: relative;
    top: -2px;
  }
}
.lang-list {
  font-size: rem-calc(14);
  margin-bottom: 0;
  .js & {
    position: absolute;
    left: 0;
    top: $spacing * 1.25;
    background-color: #fff;
    z-index: 3;
    padding: $spacing * 0.375 $spacing * 0.5;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  }
  li {
    .no-js & {
      display: inline;
      padding: 0 0.25rem;
    }
  }
  a {
    color: #000;
    text-decoration: none;
    padding: $spacing * 0.125 0;
    .js & {
      display: block;
    }
    &:hover {
      text-decoration: underline;
    }
  }
}
