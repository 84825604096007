.popup-container, .white-popup-block {
  background: #fff;
  padding: $spacing * 2 $spacing;
  text-align: left;
  max-width: rem-calc(960);
  margin: $spacing * 2 auto;
  position: relative;
  @media #{$medium-up} {
    padding: $spacing * 3 $spacing * 5;
  }
  @media #{$large-up} {
    padding: $spacing * 3 $spacing * 5;
  }
  .intro {
    border-bottom: 1px solid #d7d7d7;
    padding-bottom: $spacing;
    margin-bottom: $spacing;
    text-align: center;
    @media #{$medium-up} {
      padding-left: $spacing * 3;
      padding-right: $spacing * 3;
    }
    p.first {
      font-size: rem-calc(16);
      font-weight: bold;
      color: #000;
      margin-bottom: $spacing * 2;
    }
    h2 {
      font-size: rem-calc(14);
      margin-bottom: $spacing;
      &:after {
        display: none;
      }
    }
  }
  h1 {
    font-weight: bold;
    color: #000;
    font-size: rem-calc(20);
    margin-bottom: $spacing * 2;
    line-height: 1.4;
    @media #{$medium-up} {
      font-size: rem-calc(24);
    }
    @media #{$large-up} {
      font-size: rem-calc(26);
    }
  }
}
 
// .white-popup-block {
//   width: auto;
//   margin: 20px auto;
//   position: relative;
//   background-color: white;
//   padding: 25px;
//   max-width: 500px;
// }