.societat.fitxa {
  .head {
    margin-bottom: $spacing * 2;
  }
  .time {
    display: block;
    font-weight: bold;
    margin-bottom: $spacing;
    @media #{$medium-up} {
      font-size: rem-calc(18);
    }
    &:after {
      content: "";
      display: block;
      height: 2px;
      width: $spacing * 10;
      background-color: #313131;
      margin-top: $spacing * 0.5;
    }
  }
  h1 {
    font-size: rem-calc(28);
    line-height: 1.3;
    @media #{$medium-up} {
      font-size: rem-calc(32);
    }
    @media #{$large-up} {
      font-size: rem-calc(34);
    }
  }
  h2 {
    text-transform: uppercase;
    font-weight: bold;
    font-size: rem-calc(18);
    margin-top: $spacing * 2;
    margin-bottom: $spacing;
    &:after {
      content: "";
      display: block;
      height: 2px;
      width: $spacing * 10;
      background-color: #313131;
      margin-top: $spacing * 0.5;
    }
  }
}

// fitxa entrevista
.entrevistes.fitxa {
  .nom {
    font-weight: bold;
    font-size: rem-calc(20);
    line-height: 1.3;
    margin-bottom: $spacing * 0.5;
    @media #{$medium-up} {
      font-size: rem-calc(25);
    }
    @media #{$large-up} {
      font-size: rem-calc(27);
    }
  }
  .role {
    font-style: italic;
    margin-bottom: $spacing * 1.5;
    @media #{$medium-up} {
      margin-bottom: $spacing * 2;
    }
  }
  h1 {
    font-size: rem-calc(24);
    font-weight: bold;
    &:before {
      content: "";
      display: block;
      height: 2px;
      width: $spacing * 10;
      background-color: #313131;
      margin-bottom: $spacing;
    }
    @media #{$medium-up} {
      font-size: rem-calc(42);
      line-height: 1.2;
    }
    @media #{$large-up} {
      font-size: rem-calc(46);
    }
  }
}
