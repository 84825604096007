.main-footer {
  &--1 {
    background-color: #000;
  }
  .btn {
    background-color: #fff;
    &:hover,
    &:focus {
      color: #fff;
      background-color: #000;
    }
  }
}
